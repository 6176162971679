import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { OncologyResponseModel } from '../_models/Oncology/global-lesion-model';
import { BasicResponse } from '../core/interfaces/basic-response';
const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingMRANOVersion;
const SERVICE = 'msreadingmrano-dot-';
const BASE_URL = 'https://' + API_VERSION + SERVICE + API_URL
const SERVICE_API_URL = BASE_URL + '/reading/';
const EFFICACY_SERVICE_API_URL = BASE_URL + '/mrano-efficacy/response-assessment/';
const ASSESSMENT_SERVICE_API_URL = SERVICE_API_URL +'assessment/';
const SERVICE_CRITERIA_URL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class OncologyAssessmentService {
  private seriesLockedStatusSource = new BehaviorSubject([]);
  seriesLockedStatus = this.seriesLockedStatusSource.asObservable();

  private deletedAnnObjectsSource = new BehaviorSubject([]);
  deletedAnnObjects = this.deletedAnnObjectsSource.asObservable();

  private modalSpinnerSource  = new BehaviorSubject<boolean>(true);
   modalSpinner =this.modalSpinnerSource.asObservable();

   private openLTViewerSource = new BehaviorSubject<any>(null);
   openLTViewerData = this.openLTViewerSource.asObservable();

   private editLesionMeasurementSource = new BehaviorSubject<any>(null);
   editLesionMeasurement = this.editLesionMeasurementSource.asObservable();

   private refereshLesionListSource = new BehaviorSubject<any>(null);
  refereshLesionList = this.refereshLesionListSource.asObservable();

  private baselineNadirSPPDSource = new BehaviorSubject<any>(null);
  baselineNadirSPPD = this.baselineNadirSPPDSource.asObservable();

  private historicalBaselineNadirSource = new BehaviorSubject<any>(null);
  historicalBaselineNadir = this.historicalBaselineNadirSource.asObservable();

  private lesionReviewedSource = new BehaviorSubject<any>(null);
  lesionReviewed = this.lesionReviewedSource.asObservable();

  private baselineNadirRequestSource = new BehaviorSubject<boolean>(null);
  baselineNadirRequest = this.baselineNadirRequestSource.asObservable();

  private locationSource = new BehaviorSubject<any>(null);
  location = this.locationSource.asObservable();

  private allLesionsSource = new BehaviorSubject<any>(null);
  allLesions = this.allLesionsSource.asObservable();
  readingLesions: any[] = [];
  private criteriaSettingSource = new BehaviorSubject(null);
   criteriaSetting = this.criteriaSettingSource.asObservable();

  constructor(private http: HttpClient) {
  }
  modalSpinnerChangeState(state:boolean){
    this.modalSpinnerSource.next(state);
  }
  openLTViewer(data:any){
    this.openLTViewerSource.next(data)
  }
  onEditLesionMeasurement(data?:any){
    this.editLesionMeasurementSource.next(data);
  }
  addSeriestoSeriesLockedStatus(list:any){
    this.seriesLockedStatusSource.next(list)
  }
  deleteAnnObjectsSource(AnnObjects:any){
    this.deletedAnnObjectsSource.next(AnnObjects)
  }
  triggerLesionList(visitConfigId:number) {
    this.refereshLesionListSource.next(visitConfigId);
  }
  baselineNadirSPPDChange(data:any) {
    this.baselineNadirSPPDSource.next(data);
  }
  baselineNadirSPPDRequest(data: boolean) {
    this.baselineNadirRequestSource.next(data);
  }
  locationRequest(data: any) {
    this.locationSource.next(data);
  }

  sendLesionReviewed(data: any) {
    this.lesionReviewedSource.next(data);
  }

  addToAllLesions(lesions: any[]) {
    this.readingLesions = this.readingLesions.concat(lesions);
    this.allLesionsSource.next(this.readingLesions);
  }

  updateAllLesions(lesion: any) {
    const index = this.readingLesions.findIndex(l => l.id === lesion.id);
    this.readingLesions[index] = lesion;
    this.allLesionsSource.next(this.readingLesions);
  }

  deleteFromAllLesions(lesion: any, data: any[]) {
    this.readingLesions = this.readingLesions.filter(l => { return l.id !== lesion.id });
    data.forEach(d => {
      this.readingLesions.find(l => l.id === d.id).lesionName =  d.lesionName
      })
    this.allLesionsSource.next(this.readingLesions);
  }
  clearAllLesions() {
    this.readingLesions = [];
    this.allLesionsSource.next([])
  }

  getNumberOfAvailableReadings(studyId, readerId) {
      return this.http.get(`${SERVICE_API_URL}get-number-of-available-readings/${studyId}/${readerId}`);
  }

  getReadingByStudyIdAndReaderId(studyId, readerId) {
      return this.http.get(`${SERVICE_API_URL}get-available-readings/${studyId}/${readerId}`);
  }

  startReading(studyId, readingId) {
      return this.http.put(`${SERVICE_API_URL}start/${studyId}/${readingId}`, null);
  }

  updateReading(studyId, readingId, spentSeconds) {
      return this.http.put(`${SERVICE_API_URL}${studyId}/${readingId}`, {spentSeconds});
  }
  readingScoreLock(studyId,ScoringData){
      return this.http.post(`${SERVICE_API_URL}${ScoringData.type.toLowerCase()}/${studyId}`,ScoringData.data)
  }
  completeReading(studyId, data) {
      return this.http.put(`${SERVICE_API_URL}complete/${studyId}`, data);
  }

  updateResponsesByVisitConfigId(studyId:number,data:OncologyResponseModel) {
    const url = `${ASSESSMENT_SERVICE_API_URL}response/${studyId}`;
    return this.http.post(url,data);
  }
  getResponsesByVisitConfigId(studyId:number,endpointName:string,readingId:number,readerId:number,visitConfigId:number,generate:boolean) {
    let url = `${ASSESSMENT_SERVICE_API_URL}response/all/${studyId}/${readingId}/${visitConfigId}/${endpointName}`;
    if (generate) {
      url = `${ASSESSMENT_SERVICE_API_URL}response/generate-all/${studyId}/${readingId}/${visitConfigId}/${endpointName}/${readerId}`;
    }
    return this.http.get(url);
  }
  getEfficacyResponsesByVisitConfigId(studyId:number,readingId:number,readerId:number,visitConfigId:number) {
    let url = `${EFFICACY_SERVICE_API_URL}generate/visit?studyId=${studyId}&readingId=${readingId}&visitConfigId=${visitConfigId}&readerId=${readerId}`;
    return this.http.get(url);
  }
  getOverallResponse(studyId:number,endpointName:string,readingId:number,readerId:number,visitConfigId:number,data) {
    let url = `${ASSESSMENT_SERVICE_API_URL}response/generate-overall/${studyId}/${readingId}/${visitConfigId}/${endpointName}/${readerId}`
    return this.http.post(url, data);
  }
  getEfficacyOverallResponse(studyId:number,readingId:number) {
    let url = `${EFFICACY_SERVICE_API_URL}all-by-reading-id?studyId=${studyId}&readingId=${readingId}`
    return this.http.get(url);
  }
  getAllResponses(studyId:number, readingId:number, endpointName: string) {
    const url = `${ASSESSMENT_SERVICE_API_URL}response/all/${studyId}/${readingId}/${endpointName}`;
    return this.http.get(url);
  }
  getNadirBaselineSPPD(studyId:number,endpointName:string,readingId:number) {
    return this.http.get(`${ASSESSMENT_SERVICE_API_URL}visit/nadir/${studyId}/${readingId}/${endpointName}`)
  }
  getHistoricalNadir(studyId:number,endpointName:string,readingId:number) {
    return this.http.get(`${ASSESSMENT_SERVICE_API_URL}visit/nadir/historical/${studyId}/${readingId}/${endpointName}`)
  }
  getLesionListByVisitConfigId(studyId: number, readingId: number, endpointName: string, visitConfigId: number) {

    return this.http.get(`${ASSESSMENT_SERVICE_API_URL}lesion/list/${studyId}/${readingId}/${endpointName}/${visitConfigId}`);
  }
  getAllLesions(studyId:number, readingId:number, endpointName: string) {
    const url = `${ASSESSMENT_SERVICE_API_URL}lesion/list/${studyId}/${readingId}/${endpointName}`;
    return this.http.get(url);
  }

  updateLesionData(studyId: number, data: any, edit: boolean) {
    let url = `${ASSESSMENT_SERVICE_API_URL}lesion/${studyId}`;
    if (edit) {
      url = `${ASSESSMENT_SERVICE_API_URL}lesion/${studyId}/${data.id}`
      return this.http.put(url, data);
    }
     return this.http.post(url, data);
  }
  deleteLesion(studyId:number,lesionId:number,namePrefix:string){
    return this.http.delete(`${ASSESSMENT_SERVICE_API_URL}lesion/list/${studyId}/${lesionId}/prefix-${namePrefix}`);
 }

  getLesionImageUploadLink(studyId:number, readingId:number, seriesId:number, sliceNumber:number) {
    const url = `${ASSESSMENT_SERVICE_API_URL}snapshot/upload-link/${studyId}/${readingId}/${seriesId}/${sliceNumber}`;
    return this.http.get(url);
  }
  uplodFileToCloud(uploadUrl,formData){
    return this.http.put(uploadUrl,formData);
  }

  updateVisitComment(studyId: number,data:any) {
    const url = `${SERVICE_API_URL}visit/${studyId}`;
    return this.http.put(url,data);
  }
  lockVisitByVisitConfigId(studyId: number, readingId: number, visitConfigId: number, isLock: boolean, data:any, multiTP:boolean) {
    return this.http.post(`${ASSESSMENT_SERVICE_API_URL}visit/lock/${studyId}/${readingId}/${visitConfigId}/${isLock}?multiTP=${multiTP}`,data)
  }
  lockTimepoint(studyId, readingId, visitConfigId) {
    return this.http.patch<BasicResponse<any>>(`${SERVICE_API_URL}visit/submit/${studyId}/${readingId}/${visitConfigId}`, {})
  }
  updateTimepointStatus(studyId, readingId, visitConfigId, status) {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}visit/status/${studyId}/${readingId}/${visitConfigId}`, {
      status: status
    })
  }
  
  // get url for uploading the jason file of criteria in the link which this service make.
  uploadCriteriaFile(project, formData){
    let url = `${SERVICE_CRITERIA_URL}/criteria-settings/upload?project=`+project;
    return this.http.put(url, formData);
  }

  getUploadedCriteriaJason(){
    let url = `${SERVICE_CRITERIA_URL}/criteria-settings/all`;
     this.http.get(url).subscribe((res : any) => {
       this.criteriaSettingSource.next(res.data)
     });
  };

}
