import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BasicResponse } from '../core/interfaces/basic-response';
import { StudyModel } from '../_models/ImagingProject/study-model';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class StudyService {

  constructor(private http: HttpClient) {
  }

  create(study: any) {
    return this.http.post(requestURL + `/study`, study);
  }

  update(study: any) {
    return this.http.put(requestURL + `/study`, study);
  }

  delete(id: number) {
    return this.http.delete(requestURL + `/study/` + id);
  }

  getById(id: number): Observable<BasicResponse<StudyModel>> { // reader
    return this.http.get<BasicResponse<StudyModel>>(requestURL + `/study/` + id + `/without-rel`);
  }

  getByIdWithVisitsAndAponsors(id: number): Observable<BasicResponse<StudyModel>> { // PM
    return this.http.get<BasicResponse<StudyModel>>(requestURL + `/study/` + id + `/with-visits-sponsor`);
  }

  getByIdWithVisits(id: number): Observable<BasicResponse<StudyModel>> { // reader
    return this.http.get<BasicResponse<StudyModel>>(requestURL + `/study/` + id + `/with-visits-single`);
  }


  getByIdWithoutRelations(id: number) {
    return this.http.get(requestURL + `/study/get-study-without-relations/` + id);
  }

  getAll() {
    return this.http.get(requestURL + `/study/retrieve-all`);
  }

  getAllWithoutRelations() {
    return this.http.get(requestURL + `/study/retrieve-all-without-relations`);
  }

  getAllForImagingProjectList() {
    return this.http.get(requestURL + `/study/retrieve-all-for-clinical-trials`);
  }

  getEndpointsByStudyId(id: number) {
    return this.http.get(requestURL + `/study/get-endpoints-by-study-id/` + id);
  }

  getVisitsByStudyId(id: number) {
    return this.http.get(requestURL + `/study/get-visits-by-study-id/` + id);
  }

  getStudyPatientCodePatternAsRegEx(studyId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(requestURL + `/study/get-study-patient-code-pattern-as-regex/` + studyId);
  }

  uploadFileToCloud(uploadUrl, formData) {
    return this.http.put(uploadUrl, formData);
  }

  getImagingManualUploadUrl(studyId: number) {
    return this.http.get(requestURL + `/study/get-imaging-manual-upload-url/` + studyId);
  }

  getImagingManualDownloadUrl(studyId: number) {
    return this.http.get(requestURL + `/study/get-imaging-manual-download-url/` + studyId);
  }

  getStudyWithoutRelationsByPatientId(patientId: number): Observable<BasicResponse<StudyModel>>  {
    return this.http.get<BasicResponse<StudyModel>>(requestURL + `/study/get-study-by-patient-id-without-relations/` + patientId);
  }

}
