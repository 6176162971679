import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { SeriesNavigatorComponent } from 'src/app/components/controls/series-navigator/series-navigator.component';
import {forkJoin, interval, Observable, Observer, Subscription} from 'rxjs';
import {
  ImagingProjectService,
  LeadToolsActionManagerService,
  ReadingConfigFlexibleService,
  StudySequenceLabelService,
  StudyService,
  StudyUserService,
  ViewerAuthenticationService,
  EventService, AuthenticationService
} from 'src/app/_services';
import { ReadingMranoService } from 'src/app/_services/reading-mrano.service';
import { Store } from '@ngxs/store';
import { SetPageHeaderTitle } from 'src/app/core/data-management/actions/projects.action';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { SequenceLabelModel } from 'src/app/_models/ImagingProject/sequence-label-model';
import { FlexibleConfig } from 'src/app/core/interfaces/flexible-config';
import { BaseFlexibleConfig } from 'src/app/_models/ImagingProject/base-flexible-config';
import { MRanoLesionObject, MRanoScoringType } from 'src/app/_models/MRANO/mrano-scoring-model';
import { MranoReadingModel } from 'src/app/_models/MRANO/mrano-reading-model';
import { MranoHistoricalFormComponent } from './mrano-historical-form/mrano-historical-form.component';
import { MranoEligibilityFormComponent } from './mrano-eligibility-form/mrano-eligibility-form.component';
import { GBMService } from 'src/app/_services/gbm.service';
import { Utils } from 'src/app/_services/leadtools/lead-tools-utils';
import * as _ from 'lodash';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EventKey, FormEvent } from 'src/app/_models/Oncology/local-storage-keys.model';
import { AddLesionRequest, MarkerType, OncologyAddLesionData } from 'src/app/_models/Oncology/global-lesion-model';
import { OncologyAssessmentService } from 'src/app/_services/oncology-assessment.service';
import { FormPage, MessagingService } from "../../../_services/messaging.service";
import { VisitStatus } from 'src/app/_models/ImagingProject/IF/incidental-findings-config-model';
import { ResponseCode } from 'src/app/core/constants/response-code';
import { VisitsStatusDialogComponent } from '../IF/visits-status-dialog/visits-status-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ReadingFormBaseComponent } from '../reading-form-base.component';
import { takeUntil } from 'rxjs/operators';
import { ReadingNavigatorComponent } from '../reading-navigator/reading-navigator.component';
import { PatientService } from './../../../_services/patient.service';
import { PatientModel } from 'src/app/_models/ImagingProject/patient-model';
import { BasicResponse } from 'src/app/core/interfaces/basic-response';
import { ReadingVersion } from 'src/app/core/constants/reading-version';
import { ConfirmSigningDialogComponent } from "../../ReportSign/confirm-signing-dialog/confirm-signing-dialog.component";
import { ToastService } from "../../../_services/internal/toast.service";

interface ViewsLayoutConfiguration {
  col: number;
  row: number;
  sizex: number;
  sizey: number;
}

const AI_LABELS = ['DSC', 'DCE', 'DWI', 'ADC', 'rCBV', 'Ktrans', 'Ve', 'Vp', 'DSC reg',
  'DCE reg', 'DWI reg', 'ADC reg', 'rCBV reg', 'Ktrans reg', 'Ve reg', 'Vp reg', 'T1', 'T2', 'T1ce', 'Flair']

@Component({
  selector: 'app-mrano',
  templateUrl: './mrano-form.component.html',
  styleUrls: ['./mrano-form.component.css'],
  providers: [MessagingService]
})
export class MRANOFormComponent extends ReadingFormBaseComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChildren('viewerLT', { read: ViewContainerRef }) viewerLTs: QueryList<ViewContainerRef>;
  viewerLT: ViewContainerRef;
  @ViewChild('viewerContainer', { read: ElementRef }) viewerContainer: ElementRef;
  @ViewChild('seriesNavigator') seriesNavigator: SeriesNavigatorComponent;
  @ViewChild('historicalComponent') historicalComponent: MranoHistoricalFormComponent;
  @ViewChild('eligibilityComponent') eligibilityComponent: MranoEligibilityFormComponent;
  selectedTab = 0;
  newViewer: any = null;
  isActiveLTViewer = false;
  openedNumberOfImages = 0;
  viewsLayoutConfigurations: ViewsLayoutConfiguration[] = [];
  readingList: Array<MranoReadingModel> = [];
  currentReading: MranoReadingModel;
  userId: number;
  study: any;
  spentSecondsSubsctiption: Subscription;
  ltViewersSubsctiption: Subscription;
  ltViewerSubsctiption: Subscription;
  showViewer = false;
  spentSeconds = 0;
  showModalSpinnerLoadingData = true;
  visitedSeriesCounter = 0;
  visitConfig: any;
  readingSeries = [];
  allseries = [];
  readingSeriesDetails = [];
  isExpanded = true;
  scoringFormSelector = MRanoScoringType;
  currentScoringFormSelector = MRanoScoringType.HISTORICAL;
  readingLevel: string;
  smartRead = false;
  sequentialLock = false;
  eCRFOnly = false;
  detailsGot = false;
  validationMessages: string[];
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  validUids: string[] = [];
  availableSequenceLabels: SequenceLabelModel[] = [];
  defaultSequenceLabelIds: number[] = [];
  screenShotData: any;
  editObjectData: any;
  readingConfigResponse: any;
  resizeInterval: any = null;
  seriesStatusSubscription: Subscription;
  seriesStatus = [];
  deletedAnnotationSubscription: Subscription;
  modalSpinnerSubscription: Subscription;
  modalSpinnerAssessmentSubscription: Subscription;
  openLTViewerSubscription: Subscription;
  editMeasurementSubscription: Subscription;
  registeredImages = false;
  openedSeriesVisitConfigIds = [];
  viewerInitialized = false;

  viewerData = null;
  viewerDataIsReady = false;
  viewerDataIsLoading = false;
  // parameters for viewer less mode
  viewerLess: boolean;
  assessment: boolean;
  mranoEfficacy: boolean;
  eligibility: boolean;
  recistEligibility: boolean;
  readingId: number;
  addLesionRequestData: AddLesionRequest;
  eventSubscription: Subscription;
  lastRequest: FormEvent;
  baselineNadirSPPDRequestSubscription: Subscription;
  selectedVisit: number = 0;
  totalVolumeCalculation: boolean = false;
  eligibilityStatusSelectedByUser: string;
  enableWidespreadOrFocal: boolean = false;
  patientSubscription: Subscription;
  patientCode: string = null;
  loading = false;
  patientClinicalData = 'N/A';
  patientClinicalDataLoaded: boolean = false;
  flexibleSubscription: Subscription;

  constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    private studySequenceLabelService: StudySequenceLabelService,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    private readingMranoService: ReadingMranoService,
    private toastyService: ToastyService,
    private store: Store,
    private readingConfigService: ReadingConfigFlexibleService,
    private authenticationService: ViewerAuthenticationService,
    private imagingProjectService: ImagingProjectService,
    private gbmService: GBMService,
    private studyUserService: StudyUserService,
    public utils: Utils,
    public route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private leadtoolsActionManagerService: LeadToolsActionManagerService,
    private oncologyAssessmentService: OncologyAssessmentService,
    private messagingService: MessagingService,
    private studyService: StudyService,
    private dialog: MatDialog,
    public eventService: EventService,
    private patientService: PatientService,
    private toastService: ToastService,
  ) {
    super(route, cdr, componentFactoryResolver, eventService, utils);
  }

  ngOnInit(): void {
    // check viewer less
    this.viewerLess = this.activatedRoute.snapshot.queryParams['viewerLess'] === 'true';
    this.readingId = this.activatedRoute.snapshot.queryParams['rid'];
    this.utils.isAdvancedAnalysis = true;
    const sideNav = document.getElementsByTagName('mat-sidenav');
    (sideNav[0] as HTMLElement).style.display = 'inherit';
    if (this.viewerLess)
      (sideNav[0] as HTMLElement).style.display = 'none';

    // modal spinner
    this.modalSpinnerSubscription = this.readingMranoService.modalSpinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(state => {
      this.ModalSpinnerChangeState(state);
    });
    this.modalSpinnerAssessmentSubscription = this.oncologyAssessmentService.modalSpinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(state => {
      this.ModalSpinnerChangeState(state);
    });

    this.userId = +JSON.parse(localStorage.getItem('userId'));
    this.study = JSON.parse(localStorage.getItem('project'));
    this.configureLayouts();
    const serviceSources = [
      this.readingMranoService.getReadingByStudyIdAndReaderId(this.study.id, this.userId),
      this.readingConfigFlexibleService.getByStudyId(this.study['id'])
    ];
    forkJoin(serviceSources).subscribe(
      values => {
        const [readingResponse, readingConfigResponse] = values as any;
        if (readingResponse['responseCode'] === 200 && readingConfigResponse['responseCode'] === 200) {
          this.readingConfigResponse = readingConfigResponse;
          this.refreshReadingsList(readingResponse);
        } else if (readingResponse['responseCode'] !== 200) {
          this.showErrorMessage(readingResponse);
        } else if (readingConfigResponse['responseCode'] !== 200) {
          this.showErrorMessage(readingConfigResponse);
        }
      },
      (error) => this.showErrorMessage(error.error)
    );
    this.spentSecondsSubsctiption = interval(1000)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
      this.spentSeconds++;
    });
    // get all series status
    this.seriesStatusSubscription = this.readingMranoService.seriesLockedStatus
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
      if (response) {
        this.seriesStatus.push(...response);
        // console.log(this.seriesStatus)
      }
    });

    // get all annotation deleted when lesion deleted
    this.deletedAnnotationSubscription = this.readingMranoService.deletedAnnObjects
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
      if (response) {
        console.log(response);
      }
    });

    // open lt viewer from children
    this.openLTViewerSubscription = this.readingMranoService.openLTViewerData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        if (data) {
          this.onEditMeasurement(data);
          const message: FormEvent = { key: EventKey.OPEN_VIEWER, value: data };
          this.messagingService.sendRequest(message, FormPage.MRANO);
        }
    });

    this.editObjectData = null;
    // edit lesion measurement
    this.editMeasurementSubscription = this.readingMranoService.editLesionMeasurement
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        if (data) {
          this.onEditMeasurement(data);
          const message: FormEvent = { key: EventKey.ELIGIBILITY_ADD_LESION, value: data };
          this.messagingService.sendRequest(message, FormPage.MRANO);
        }
    });

    // request to calculate baseline and nadir sppd
    this.baselineNadirSPPDRequestSubscription = this.oncologyAssessmentService.baselineNadirRequest
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
      if (data) {
        this.getBaseLineAndHistoricalNadir();
      }
    });

    this.updateEventSubscriptionForCurrentReading();

    // get criteria setting for assessment
    this.oncologyAssessmentService.getUploadedCriteriaJason();
  }

  openReadingsIdListDialog() {
    const dialogRef = this.dialog.open(ReadingNavigatorComponent, {
      width: '650px',
      height: '556px',
      data: this.readingList,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((readingId: any) => {
      this.patientClinicalDataLoaded = false;
      if (readingId && readingId !== this.currentReading.id)
        this.skipCurrentReadingAndUpdate(readingId);
    });
  }
  // handeling local storage events
  handleEvents(event: FormEvent) {
    if (!event.value)
      return
    //viewerLess
    if (this.viewerLess) {
      switch (event.key) {
        case EventKey.EXIT_FORM:
          this.router.navigate(['/'])
          break;
        case EventKey.ELIGIBILITY_LESION_DATA:
          if (this.currentReading?.historicalScan?.lock && !this.currentReading?.eligibilityScan?.lock) {
            this.eligibilityComponent.addObjectFromLeadtools(event.value, this.editObjectData?.editMode, this.editObjectData?.editType, this.editObjectData?.currentLesionData, this.editObjectData.type);
          } else if (!this.currentReading?.historicalScan?.lock) {
            this.historicalComponent.addObjectFromLeadtools(event.value, this.editObjectData?.editMode, this.editObjectData?.editType, this.editObjectData?.currentLesionData, this.editObjectData.type);
          }
          break;
        default:
          break;
      }
    }
    if (!this.viewerLess) {
      this.lastRequest = event;
      console.log(event);
      switch (event.key) {

        case EventKey.ADD_LESION_REQUEST:
          this.onEditMeasurement(null);
          this.addLesionRequestData = event.value;
          try {
            this.newLTViewer.instance.addMarker(this.addLesionRequestData.markerType, undefined, undefined, this.addLesionRequestData.lesionName);
          } catch (e) {
            this.toastOptions.title = 'ERROR: Image not found';
            this.toastOptions.msg = 'The image must be open before adding lesion';
            this.toastyService.error(this.toastOptions);
            this.addLesionRequestData = null;
          }
          break;

        case EventKey.SCREENSHOT_REQUEST:
          this.makeSnapshotFromViewerOverWebsocket(event);
          break;

        case EventKey.EDIT_LESION_DATA:
          this.addLesionRequestData = event.value.requestData;
          this.newLTViewer.instance.setMarkerName(this.addLesionRequestData.lesionName);
          if (!event.value?.currentLesionData?.snapshot) {
            try {
              this.newLTViewer.instance.addMarker(this.addLesionRequestData.markerType, undefined, undefined, this.addLesionRequestData.lesionName);
            } catch (e) {
              this.toastOptions.title = 'ERROR: Image not found';
              this.toastOptions.msg = 'The image must be open before adding lesion';
              this.toastyService.error(this.toastOptions);
              this.addLesionRequestData = null;
            }
          }
          this.goToLesion(event.value?.currentLesionData, true, true);
          this.onEditMeasurement(event.value);
          break;

        case EventKey.GO_TO_LESION:
          this.goToLesion(event.value?.currentLesionData, true, false);
          break;

        case EventKey.LOCK_VISIT:
          this.scoreLocked(event.value);
          break;

        case EventKey.SKIP_FORM:
          this.skipCurrentReading(event.value.index, event.value.removeCurrent);
          break;

        case EventKey.SUBMIT_FORM:
          this.checkReadings(event.value.index);
          break;

        case EventKey.END_READING:
          this.readingList.splice(0, 1);
          this.currentReading = undefined;
          break;

        case EventKey.OPEN_VIEWER:
          this.openLTViewerFromChildren(event.value);
          break;
        case EventKey.ELIGIBILITY_ADD_LESION:
          this.onEditMeasurement(event.value);
          if (event.value.editMode === false)
            this.newLTViewer.instance.addMarker(event.value.type === 'MIDLINESHIFT' ? MarkerType.RULER : MarkerType.BI_RULER, undefined, undefined, '');
          break;

        default:
          break;
      }
    }
  }

  // make snapshot from viewer after request
  makeSnapshotFromViewerOverWebsocket(event: FormEvent) {
    try {
      const snapshot = this.leadtoolsActionManagerService.popupCapture();
      const data = {
        cellInfo: {
          seriesId: snapshot.cellInfo.seriesId,
          seriesUID: snapshot.cellInfo.seriesUID,
          modality: snapshot.cellInfo.modality,
          label: snapshot.cellInfo.label
        },
        sliceNum: snapshot.sliceNum,
        image: snapshot.image
      };
      const message: FormEvent = { key: EventKey.SCREENSHOT_DATA, value: data, destination: event.publisherId };
      this.messagingService.sendRequest(message);
    } catch {
      this.toastOptions.title = 'ERROR: Image not found';
      this.toastOptions.msg = 'The image must be open before capture snapshot';
      this.toastyService.error(this.toastOptions);
      this.addLesionRequestData = null;
    }
  }

  // open scoring form in new window
  open(): void {
    this.updateEventSubscriptionForCurrentReading();
    const a = document.createElement('a');
    const objectUrl = window.location.href.replace(/\?.*$/, "") + `?viewerLess=true&rid=${this.currentReading.id}`;
    a.href = objectUrl;
    a.target = '_blank';
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  updateEventSubscriptionForCurrentReading() {
    if (this.eventSubscription)
      this.eventSubscription.unsubscribe();

    if (this.currentReading) this.readingId = this.currentReading.id;
    // else this.readingId = null;
    this.updateRouteQueryParams();
    this.userId = +JSON.parse(localStorage.getItem('userId'));
    if (this.readingId) {
      const postfix = '';//this.eligibility ? '' : (this.viewerLess ? 'viewerLess' : '');
      this.eventSubscription = this.messagingService.init(this.userId, this.readingId, FormPage.MRANO, postfix)
        .subscribe(this.handleEvents.bind(this));
    }
  }

  updateRouteQueryParams() {
    const queryParams: Params = {};
    queryParams['rid'] = this.readingId;
    queryParams['viewerLess'] = this.viewerLess ? this.viewerLess : false;

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        // queryParamsHandling: 'merge', // remove to replace all query params by provided,
        // replaceUrl: true
      });
  }

  // go to lesion from localstorage event
  goToLesion(lesion?: any, toast?: boolean, edit?: boolean) {
    if (lesion) {
      let seriesIdAndSliceNumber;
      if (this.assessment || this.mranoEfficacy || this.recistEligibility) {
        seriesIdAndSliceNumber = {
          seriesId: lesion.snapshot ? lesion.snapshot.seriesId : lesion.seriesId,
          sliceNumber: lesion.snapshot ? lesion.snapshot.sliceNumber : lesion.sliceNumber
        };
      } else {
        seriesIdAndSliceNumber = {
          seriesId: lesion.screenShot ? lesion.screenShot.seriesId : lesion?.rois[0]?.seriesId,
          sliceNumber: lesion?.screenShot ? lesion.screenShot.sliceNumber : lesion?.rois[0]?.sliceNumber
        };
      }

      this.openLTViewerFromChildren(seriesIdAndSliceNumber)

      if (!edit) {
        this.readingMranoService.onEditLesionMeasurement(null);
      }
      if (toast) {
        this.toastOptions.title = 'ID 60: ' + lesion.lesionName + ' is loaded successfully and available in the viewer';
        this.toastOptions.msg = 'Reader can see the associated ROI in the new slot in the image viewer';
        this.toastOptions.timeout = 15000;
        this.toastyService.success(this.toastOptions);
      }
    } else {
      this.readingMranoService.onEditLesionMeasurement(null);
      if (toast) {
        this.toastOptions.title = 'ID 61: ' + lesion.lesionName + ' loading failure'
        this.toastOptions.msg = 'Lesion is not loaded due to some reasons. Try again or contact support team'
        this.toastyService.error(this.toastOptions);
      }
    }
  }

  showReadingForm(): void {
    this.isExpanded = true;
    setTimeout(() => {
      this.onResize();
    }, 100);
  }

  hideReadingForm(): void {
    this.isExpanded = false;
    setTimeout(() => {
      this.onResize();
    }, 100);
  }

  ngOnDestroy(): void {
    this.destroy();

    if (this.spentSecondsSubsctiption) {
      this.spentSecondsSubsctiption.unsubscribe();
    }

    if (this.ltViewerSubsctiption) {
      this.ltViewerSubsctiption.unsubscribe();
    }

    if (this.ltViewersSubsctiption) {
      this.ltViewersSubsctiption.unsubscribe();
    }

    if (this.currentReading) {
      this.readingMranoService.updateReading(
        this.study.id, this.currentReading.id, this.spentSeconds
      ).subscribe(response => {
      });
    }

    if (this.seriesStatusSubscription) {
      this.seriesStatusSubscription.unsubscribe();
    }

    if (this.deletedAnnotationSubscription) {
      this.deletedAnnotationSubscription.unsubscribe();
    }

    if (this.modalSpinnerSubscription) {
      this.modalSpinnerSubscription.unsubscribe();
    }

    if (this.openLTViewerSubscription) {
      this.openLTViewerSubscription.unsubscribe();
    }

    this.readingMranoService.onEditLesionMeasurement(null);
    this.editObjectData = null;
    if (this.editMeasurementSubscription) {
      this.editMeasurementSubscription.unsubscribe();
    }

    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }

    if (this.baselineNadirSPPDRequestSubscription) {
      this.baselineNadirSPPDRequestSubscription.unsubscribe();
    }

    if (this.patientSubscription) {
      this.patientSubscription.unsubscribe();
    }

    const message: FormEvent = { key: EventKey.EXIT_FORM, value: { exit: true } };
    this.messagingService.sendRequest(message, FormPage.MRANO);

    this.utils.readingId = null;
    this.utils.isAdvancedAnalysis = false;

    if(this.newLTViewer)
      this.newLTViewer.instance.statDatas = null;

    if (this.flexibleSubscription) {
      this.flexibleSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.ltViewersSubsctiption = this.viewerLTs.changes
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((comps: QueryList<ViewContainerRef>) => {
      if (!this.viewerLT && comps.first) {
        this.viewerLT = comps.first;
        // this.preOpenLTViewer();
      }
    });
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges(); // fix ExpressionChangedAfterItHasBeenCheckedError
  }

  configureFromConfigFlexible(response) {
    let existed = false;
    this.enableWidespreadOrFocal = false;
    const data = response['data'];
    if (data) {
      for (let i1 = 0; i1 < data.length; i1++) {
        const i = data[i1];
        if (i.config['endpoint'].name.toLowerCase() === 'rano' && this.currentReading.configId === i['id']) {
          if ([ReadingVersion.MRANO_EFFICACY, ReadingVersion.RECIST_ELIGIBILITY].includes(i.config.readingVersion)) {
            this.oncologyAssessmentService.locationRequest(i.config.locations);
          }
          if (i.config['imageViewerConfigurationType'] === 'eCRF only (RANO)')
            this.eCRFOnly = true;
          this.readingLevel = i.config.readingLevel;
          if (i.config.readingLevelConfig.includes('SMART')) {
            this.smartRead = true;
          }
          if (i.config.readingLevelConfig.includes('SEQUENTIAL')) {
            this.sequentialLock = true;
          }
          if (i.config.totalVolumeCuttOffEnable) {
            this.totalVolumeCalculation = true;
          }
          if (i.config.enableWidespreadOrFocalLeptomeningeal) {
            this.enableWidespreadOrFocal = true;
          }
          existed = true;
          break;
        }
      }
    }
    if (!existed) {
      this.toastOptions.title = 'ERROR Reading configuration not existed'
      this.toastOptions.msg = 'There is no active reading configuration for this reading'
      this.toastyService.error(this.toastOptions);
    }
  }

  refreshReadingsList(response) {
    let index = 0;
    this.readingList = [];
    const read = response['data'];
    if (read != null) {
      this.readingList = read;
      if (this.readingList.length) {
        if (this.readingId) {
         index = this.readingList.findIndex(r => r.id == this.readingId);
        }
        this.initReading(this.readingList[index]);
      } else {
        this.detailsGot = true;
        this.readingMranoService.modalSpinnerChangeState(false);
      }
    }
  }

  getPatientClinicalData(response: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const data = response.data.find(d => d.id === this.currentReading.configId);
      const clinicalData = data?.config?.mranoClinicalData ?? [];

      if (clinicalData.length > 0) {
        this.patientService.getById(this.currentReading.patientId).subscribe(
          resp => {
            const patientCode = resp.data.patientCode;
            this.patientClinicalData = clinicalData.find(
              c => c.patientCode.toString() === patientCode.toString()
            )?.clinicalData;
            this.updateTransformedPatientClinicalData();
            resolve(); // Resolve the Promise when data is set
          },
          error => reject(error) // Reject the Promise on error
        );
      } else {
        resolve(); // Resolve even if there's no data
      }
    });
  }

  initReading(reading: MranoReadingModel) {
    if (this.newLTViewer) {
      this.newLTViewer.instance.removeMarkerName();
    }
    this.openedSeriesVisitConfigIds = [];
    this.seriesStatus = [];
    this.detailsGot = false;
    this.eCRFOnly = false;
    this.showModalSpinnerLoadingData = true;
    this.currentReading = reading;
    this.currentScoringFormSelector = MRanoScoringType.HISTORICAL;
    this.currentReading.visits = reading.visits.sort((a, b) => a.order > b.order ? 1 : -1);
    this.assessment = [ReadingVersion.RANO_ADJUDICATION.toString(), ReadingVersion.RANO_ASSESSMENT.toString()].includes(this.currentReading.readingVersion);
    this.mranoEfficacy = this.currentReading.readingVersion === ReadingVersion.MRANO_EFFICACY;
    this.eligibility = this.currentReading.readingVersion === ReadingVersion.MRANO_BASIC;
    this.recistEligibility =  this.currentReading.readingVersion === ReadingVersion.RECIST_ELIGIBILITY;
    this.oncologyAssessmentService.clearAllLesions();
    this.resetScoringForms();
    this.utils.isMranoEfficancy = this.mranoEfficacy;
    this.utils.readingId = this.currentReading.id;

    this.patientSubscription = this.patientService.getById(reading.patientId).subscribe((response: BasicResponse<PatientModel>) => {
      this.patientCode = response.data.patientCode;
    });

    // filling up active visit list for EfficacyMRANO project
    if (this.mranoEfficacy) {
      this.currentReading.visits.forEach(visit => {
        this.updateActiveVisitList(visit);
      });
    }

    this.visitedSeriesCounter = 0;
    this.visitConfig = this.currentReading.visits;
    this.currentReading.readingLevel = this.readingLevel;

    this.spentSeconds = this.currentReading.timeSpent || 0;
    if (this.currentReading.status.code < 700) {       // in_progress
      this.readingMranoService.startReading(this.currentReading.studyId, this.currentReading.id).subscribe(response => {
        if (response['responseCode'] === 200) {
          this.currentReading.status = response['data'].status;
        } else {
          this.showErrorMessage(response);
        }
      });
    }

    this.configureFromConfigFlexible(this.readingConfigResponse);
    if (this.recistEligibility) {
      this.getPatientClinicalData(this.readingConfigResponse).then(() => {
        console.log('After Data is Set:', this.patientClinicalData);
        this.patientClinicalDataLoaded = true;
      });
    }
    if (this.viewerLess) {
      setTimeout(() => { this.detailsGot = true; }, 0);
      this.showModalSpinnerLoadingData = false;
    }
    if (!this.viewerLess) {
      this.showModalSpinnerLoadingData = false;
      this.initRegisteredImages(this.assessment || this.mranoEfficacy);
    }

    this.updateEventSubscriptionForCurrentReading();
  }

  initRegisteredImages(isAssessment) {
    try {
      this.readingSeries = [];
      this.studyService.getByIdWithoutRelations(this.currentReading.studyId).subscribe((resp: any) => {
        (this.currentReading as any).bucketName = resp.data.bucketLocation;
        if (isAssessment)
          this.loadRanoAssessmentData();
        else
          this.loadRanoWithEligibility();
      });
    } catch (error) {
      console.log('==> Error... ', error);
    }
  }

  loadRanoWithEligibility() {
    console.log('============> Rano with eligibility')
    const serviceSources = [
      Promise.resolve({})
      // this.gbmService.status({
      //   visitConfigId: this.currentReading.visits[0].visitConfigId,
      //   flexibleConfigId: this.currentReading.configId,
      //   studyId: this.study.id,
      //   bucketName: '' + (this.currentReading as any).bucketName
      // }, null),
      // this.gbmService.status({
      //   visitConfigId: this.currentReading.visits.length === 2
      //     ? this.currentReading.visits[1].visitConfigId : this.currentReading.visits[0].visitConfigId,
      //   flexibleConfigId: this.currentReading.configId,
      //   studyId: this.study.id,
      //   bucketName: '' + (this.currentReading as any).bucketName
      // }, null)
    ];
    forkJoin(serviceSources).subscribe(values => {
      const [baseLine, visit] = values as any;
      const viewerDataResults: Array<Observable<any>> = [];

      if (baseLine['state'] === 'DONE' && visit['state'] === 'DONE') {
        this.registeredImages = true;
        this.readingSeriesDetails = [];
        console.log('GBM State is DONE!');
        this.currentReading.visits.forEach(v => {
          this.imagingProjectService.getVisitConfig(v.visitConfigId.toString()).subscribe(
            resul => {
              if (resul['responseCode'] === 200) {
                let seriesDetails = resul['data'].series;
                // const visitName = resul['data'].visitBlindName ? resul['data'].visitBlindName : resul['data'].visitName;
                const visitName = resul['data'].visitName;
                seriesDetails.forEach(s => {
                  this.readingSeries.push(s);
                });
                seriesDetails = seriesDetails.filter(rs => rs.label == 'T1' ||
                  rs.seriesDescription == 'FLAIR Reg' ||
                  rs.seriesDescription == 'T2 Reg' ||
                  rs.seriesDescription == 'T1post Reg' ||
                  AI_LABELS.includes(rs.seriesDescription) ||
                  AI_LABELS.includes(rs.label) );
                seriesDetails.forEach(sd => {
                  sd['seriesVisitName'] = visitName;
                  switch (sd.seriesDescription) {
                    case 'FLAIR Reg':
                      sd['label'] = 'Flair';
                      break;
                    case 'T2 Reg':
                      sd['label'] = 'T2';
                      break;
                    case 'T1post Reg':
                      sd['label'] = 'T1ce';
                      break;
                    default:
                      if (sd.seriesDescription.includes('Reg'))
                        sd['lable'] = sd.seriesDescription.replace(' Reg', '');
                      break;
                  }
                  sd['modality'] = 'MR';
                  sd['projectModality'] = 'MRI';
                  sd['visitConfigId'] = v.visitConfigId;
                  sd['order'] = v.order;
                  this.readingSeriesDetails.push(sd);
                  this.readingSeriesDetails = this.readingSeriesDetails.sort((a, b) => a.order > b.order ? 1 : -1);
                  const series = sd;
                  if (series != null) {
                    series.seriesId = sd.id;
                    series.seriesInstanceUID = sd.seriesUID;
                    series.timepoint = visitName;
                    this.allseries.push(series);
                    const info = { 'selectedPage': 'Reading' };
                    this.viewerDataIsLoading = true;
                    viewerDataResults.push(this.utils.initViewerData(series, this, info));
                  }
                  this.readingConfigService.getById(this.currentReading.configId).subscribe(configResp => {
                    this.detailsGot = true;
                    this.initDefaultSequenceLabels(configResp.data);
                  });
                });
                if (viewerDataResults.length) {
                  forkJoin(viewerDataResults).subscribe(() => {
                    this.viewerData['shouldHidePatient'] = true;
                    this.viewerDataIsReady = true;
                    this.viewerDataIsLoading = false;

                    if (this.viewerData.studyUserRoles && this.viewerData.studyUserRoles.find(i => i.roleType === 'READER')) {
                      this.viewerData.toolbarExcludeItems = ['ShowDicom'];
                    }

                    if (!this.viewerInitialized) {
                      this.openLTViewer(['-1']);
                      this._updateViewerToolbar();
                      this.viewerInitialized = true;
                    }

                    this.cdr.detectChanges();
                  });
                }
              }
            }
          );
        });
      } else {
        this.registeredImages = false;
        this.visitConfig.forEach(visits => {
          this.imagingProjectService.getVisitConfig(visits.visitConfigId).subscribe(imagProjResp => {
            const visitConfig = imagProjResp['data'];
            if (visitConfig != null) {
              visitConfig.series.forEach(visitSeries => {
                const series = visitSeries;
                if (series != null) {
                  this.allseries.push(series);
                  series.seriesId = visitSeries.id;
                  series.seriesInstanceUID = visitSeries.seriesUID;
                  // series.timepoint = visitConfig.visitBlindName ? visitConfig.visitBlindName : visitConfig.visitName;
                  series.timepoint = visitConfig.visitName;

                  const info = { 'selectedPage': 'Reading' };
                  this.viewerDataIsLoading = true;
                  viewerDataResults.push(this.utils.initViewerData(series, this, info));
                }
                if (viewerDataResults.length) {
                  forkJoin(viewerDataResults).subscribe(() => {
                    this.viewerData['shouldHidePatient'] = true;
                    // this.viewerDataIsReady = true;
                    this.viewerDataIsLoading = false;
                    // this.openLTViewer(['-1']);
                    // this._updateViewerToolbar();
                    if (!this.viewerInitialized) {
                      this.viewerDataIsReady = true;
                      this.openLTViewer(['-1']);
                      this._updateViewerToolbar();
                      this.viewerInitialized = true;
                    }
                  });
                } else {
                  // this.viewerDataIsReady = true;
                  // this.openLTViewer(['-1']);
                  // this._updateViewerToolbar();
                  if (!this.viewerInitialized) {
                    this.viewerDataIsReady = true;
                    this.openLTViewer(['-1']);
                    this._updateViewerToolbar();
                    this.viewerInitialized = true;
                  }
                }
              });
            }
          });
        });
        if (this.currentReading.visits) {
          this.readingSeries = [];
          this.readingSeriesDetails = [];
          const seriesIds = [];
          this.currentReading.visits.forEach(v => {
            v.series.forEach(s => {
              this.readingSeries.push(s);
              seriesIds.push(s.seriesId);
            });
          });
          this.readingConfigService.getById(this.currentReading.configId).subscribe(configResp => {
            this.detailsGot = true;
            this.initDefaultSequenceLabels(configResp.data);
          });
          this.studySequenceLabelService.getStudySeriesBySeriesIds(seriesIds).subscribe(response => {
            if (response['responseCode'] === 200) {
              if (response['data']) {
                this.readingSeriesDetails = (response['data'] as any[])//.sort(this.GetSortOrder('visitConfig'));
                this.readingSeriesDetails.forEach(sd => {
                  const visit = this.getVisitInfo(sd.id, false);
                  if (visit != null) {
                    sd['seriesVisitName'] = visit.timepoint;
                    sd['visitConfigId'] = visit.visitConfigId;
                    sd['order'] = visit.order;
                    // this.viewerData[sd.id].timepoint = visit.timepoint;
                  }
                });
                this.readingSeriesDetails = this.readingSeriesDetails.sort((a, b) => a.order > b.order ? 1 : -1);
              }
            } else {
              this.showErrorMessage(response);
            }
          });
        }
      }

      if (this.viewerData) {
        this.viewerData.isMRano = true;
      }
    }, (error) => {
      console.error(error);
    });
  }


  loadRanoAssessmentData() {
    console.log('============> Rano assessment')

    const services = [Promise.resolve({})]
    // this.currentReading.visits.forEach(v => {
    //   services.push(
    //     this.gbmService.status({
    //       visitConfigId: v.visitConfigId,
    //       flexibleConfigId: this.currentReading.configId,
    //       studyId: this.study.id,
    //       bucketName: '' + (this.currentReading as any).bucketName
    //     }, null)
    //   )
    // })
    forkJoin(services).subscribe(values => {
      const serviceResponses = values as any[];
      const viewerDataResults: Array<Observable<any>> = [];

      if (serviceResponses.filter(r => r.state !== 'DONE').length == 0) {
        this.registeredImages = true;
        this.readingSeriesDetails = [];
        console.log('GBM State is DONE!');
        this.currentReading.visits.forEach(v => {
          this.imagingProjectService.getVisitConfig(v.visitConfigId.toString()).subscribe(
            resul => {
              if (resul['responseCode'] === 200) {
                let seriesDetails = resul['data'].series;
                // const visitName = resul['data'].visitBlindName ? resul['data'].visitBlindName : resul['data'].visitName;
                const visitName = resul['data'].visitName;
                seriesDetails.forEach(s => {
                  this.readingSeries.push(s);
                });
                seriesDetails = seriesDetails.filter(rs => rs.label == 'T1' ||
                  rs.seriesDescription == 'FLAIR Reg' ||
                  rs.seriesDescription == 'T2 Reg' ||
                  rs.seriesDescription == 'T1post Reg' ||
                  AI_LABELS.includes(rs.seriesDescription) ||
                  AI_LABELS.includes(rs.label));
                seriesDetails.forEach(sd => {
                  sd['seriesVisitName'] = visitName;
                  switch (sd.seriesDescription) {
                    case 'FLAIR Reg':
                      sd['label'] = 'Flair';
                      break;
                    case 'T2 Reg':
                      sd['label'] = 'T2';
                      break;
                    case 'T1post Reg':
                      sd['label'] = 'T1ce';
                      break;
                  }
                  sd['modality'] = 'MR';
                  sd['projectModality'] = 'MRI';
                  sd['visitConfigId'] = v.visitConfigId;
                  sd['order'] = v.order;
                  this.readingSeriesDetails.push(sd);
                  this.readingSeriesDetails = this.readingSeriesDetails.sort((a, b) => a.order > b.order ? 1 : -1);
                  const series = sd;
                  if (series != null) {
                    series.seriesId = sd.id;
                    series.seriesInstanceUID = sd.seriesUID;
                    series.timepoint = visitName;
                    this.allseries.push(series);
                    const info = { 'selectedPage': 'Reading' };
                    this.viewerDataIsLoading = true;
                    viewerDataResults.push(this.utils.initViewerData(series, this, info));
                  }
                  this.readingConfigService.getById(this.currentReading.configId).subscribe(configResp => {
                    this.detailsGot = true;
                    this.initDefaultSequenceLabels(configResp.data);
                  });
                });
                if (viewerDataResults.length) {
                  forkJoin(viewerDataResults).subscribe(() => {
                    this.viewerData['shouldHidePatient'] = true;
                    this.viewerDataIsReady = true;
                    this.viewerDataIsLoading = false;

                    if (!this.viewerInitialized) {
                      this.openLTViewer(['-1']);
                      this._updateViewerToolbar();
                      this.viewerInitialized = true;
                    }

                    if (this.viewerData.studyUserRoles && this.viewerData.studyUserRoles.find(i => i.roleType === 'READER')) {
                      this.viewerData.toolbarExcludeItems = ['ShowDicom'];
                    }

                    this.cdr.detectChanges();
                  });
                }
              }
            }
          );
        });
      } else {
        this.registeredImages = false;
        this.visitConfig.forEach(visits => {
          this.imagingProjectService.getVisitConfig(visits.visitConfigId).subscribe(imagProjResp => {
            const visitConfig = imagProjResp['data'];
            if (visitConfig != null) {
              visitConfig.series.forEach(visitSeries => {
                const series = visitSeries;
                if (series != null) {
                  this.allseries.push(series);
                  series.seriesId = visitSeries.id;
                  series.seriesInstanceUID = visitSeries.seriesUID;
                  // series.timepoint = visitConfig.visitBlindName ? visitConfig.visitBlindName : visitConfig.visitName;
                  series.timepoint = visitConfig.visitName;

                  const info = { 'selectedPage': 'Reading' };
                  this.viewerDataIsLoading = true;
                  viewerDataResults.push(this.utils.initViewerData(series, this, info));
                }
              });
            }
            if (viewerDataResults.length) {
              forkJoin(viewerDataResults).subscribe(() => {
                this.viewerData['shouldHidePatient'] = true;
                this.viewerDataIsReady = true;
                this.viewerDataIsLoading = false;
                this.openLTViewer(['-1']);
                this._updateViewerToolbar();
              });
            } else {
              this.viewerDataIsReady = true;
              this.openLTViewer(['-1']);
              this._updateViewerToolbar();
            }
          });
        });
        if (this.currentReading.visits) {
          this.readingSeries = [];
          this.readingSeriesDetails = [];
          const seriesIds = [];
          this.currentReading.visits.forEach(v => {
            v.series.forEach(s => {
              this.readingSeries.push(s);
              seriesIds.push(s.seriesId);
            });
          });
          this.readingConfigService.getById(this.currentReading.configId).subscribe(configResp => {
            this.detailsGot = true;
            this.initDefaultSequenceLabels(configResp.data);
          });
          this.studySequenceLabelService.getStudySeriesBySeriesIds(seriesIds).subscribe(response => {
            if (response['responseCode'] === 200) {
              if (response['data']) {
                this.readingSeriesDetails = (response['data'] as any[])//.sort(this.GetSortOrder('visitConfig'));
                // console.log(this.readingSeriesDetails)
                this.readingSeriesDetails.forEach(sd => {
                  const visit = this.getVisitInfo(sd.id, false);
                  if (visit != null) {
                    sd['seriesVisitName'] = visit.timepoint;
                    sd['visitConfigId'] = visit.visitConfigId;
                    sd['order'] = visit.order;
                    // this.viewerData[sd.id].timepoint = visit.timepoint;
                  }
                });
                this.readingSeriesDetails = this.readingSeriesDetails.sort((a, b) => a.order > b.order ? 1 : -1)
              }
            } else {
              this.showErrorMessage(response);
            }
          });
        }
      }
    }, (error) => {
      console.error(error);
    });
  }

  // init selected tab for assessment
  activeSelectedTab() {
    this.currentReading.visits.every((visit, index) => {
      if (!visit.lock && !visit.noUpload) {
        this.selectedTab = index;
        return false;
      }
      return true;
    })
  }

  initDefaultSequenceLabels(readingConfig: FlexibleConfig<BaseFlexibleConfig>): void {
    const configSequenceLabels: SequenceLabelModel[] = [];
    readingConfig.config.modalities.forEach(m => {
      m.sequenceLabels.forEach(s => configSequenceLabels.push(s));
    });
    this.availableSequenceLabels = configSequenceLabels;
    if (!!readingConfig.config.anatomySelector) {
      this.defaultSequenceLabelIds = (readingConfig.config.anatomySelector as { id: number }[])
        .filter(label => this.availableSequenceLabels.find(studyLabel => studyLabel.id === label.id)).map(l => l.id);
    } else {
      this.defaultSequenceLabelIds = this.availableSequenceLabels.map(l => l.id);
    }
  }

  getVisitInfo(seriesId, registered: boolean) {
    let series;
    if (registered) {
      series = this.readingSeriesDetails.find(s => s.id === seriesId)
    } else {
      this.currentReading.visits.forEach(v => {
        const serie = v.series.find(s => s.seriesId === seriesId);
        if (serie) {
          series = v;
        }
      });
    }
    return series;
  }

  configureLayouts() {
    /* layout configuration for multiple viewers */
    this.viewsLayoutConfigurations.push({ 'col': 0, 'row': 0, 'sizex': 12, 'sizey': 12 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 0, 'sizex': 6, 'sizey': 12 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 6, 'sizex': 6, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 6, 'sizex': 6, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 8, 'row': 6, 'sizex': 4, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 8, 'row': 6, 'sizex': 4, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 9, 'row': 6, 'sizex': 3, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 9, 'row': 6, 'sizex': 3, 'sizey': 6 });
  }

  /* --------------------------- navigator methods --------------------------- */

  onVisitedSeriesCounter(event) {
    this.visitedSeriesCounter = event;
  }

  onCloseAllViewers(event) {
    console.log('--- onCloseAllViewers');

    this.newLTViewer.instance.removeAllCells();
  }

  onOpenViewer(event) {
    if (event != null) {
      const seriesArr = [];
      event.forEach((seriesId) => {
        if (this.openedSeriesVisitConfigIds.filter(s => s.sereisId === seriesId).length === 0) {
          this.openedSeriesVisitConfigIds.push({
            seriesId: seriesId,
            visitConfigId: this.getVisitInfo(seriesId, this.registeredImages).visitConfigId
          });
        }
        if (this.registeredImages) {
          const series = this.readingSeriesDetails.find(s => s.id === seriesId);
          seriesArr.push(series.id);
        } else {
          const series = this.readingSeries.find(s => s.seriesId === seriesId);
          seriesArr.push(series.seriesId);
        }
      });
      localStorage.setItem('visitConfigIds', JSON.stringify(this.openedSeriesVisitConfigIds))
      this.openLTViewer(seriesArr);
    }
  }

  /* --------------------------- viewer methods --------------------------- */

  openNewLTViewer(seriesInstanceUIDs) {
    this.createViewerCmpInstance(this.viewerLT);
    this.newLTViewer.instance.seriesIds = seriesInstanceUIDs;
    this.newLTViewer.instance.shouldShowDICOM = true; // show scan date
    this.newLTViewer.instance.useHangingProtocol = false;
    this.newLTViewer.instance.shouldApplyProtocol = false;

    this.newLTViewer.instance.onClose
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ serID, shouldCloseViewer }) => {
      this.closeLTViewerSeries(serID, shouldCloseViewer);
    });

    this.newLTViewer.instance.onMakeSnapShotListner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
      const series = this.allseries.find(s => s.seriesUID === data.seriesId);
      this.screenShotData = {
        seriesId: series.seriesId,
        image: data.image,
        preview: data.preview,
        seriesUID: data.seriesId,
        sliceNumber: data.sliceNum,
        modalityName: series.projectModality,
        sequenceLabelName: series.label,
      };

      if (this.screenShotData) {
        this.toastOptions.title = 'ID 46: The screenshot of the lesion is acquired successfully';
        this.toastOptions.msg = 'The screenshot includes measurement info';
        this.toastyService.success(this.toastOptions);
      } else {
        this.toastOptions.title = 'ID 47: The screenshot failure';
        this.toastOptions.msg = 'Screenshot is not made due to some reason. Try again or contact support team';
        this.toastyService.error(this.toastOptions);
      }
    });
    this.newLTViewer.instance.onConfirmLesion
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (objectData) => {
          this.onViewerDataToAddLesion(objectData);
      }
    );
    this.newLTViewer.instance.onConfirmMarker
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (objectData) => {
          if (objectData) {
            this.onViewerDataToAddLesion(objectData);
          }
      }
    );

    this.newLTViewer.instance.onDemriqStatData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data !== null) {
          const visitId = this.currentReading.visits.find(visit => visit.series.find(s => s.seriesId === data.vois[0].seriesId)).id;
          this.readingMranoService.storeStatisticDataByVisit(data, visitId).subscribe(response => {
            if (response) {
              this.toastOptions.title = 'SUCCESS Statistic data saved!';
              this.toastOptions.msg = 'Adavanced analysis statistic data successfully stored';
              this.toastyService.success(this.toastOptions);
              this.newLTViewer.instance.statDatas.find(visit => visit.id === visitId).scoring = data;
            }
          })
        } else {
          this.showModalSpinnerLoadingData = false;
          this.toastOptions.title = 'Something went wrong.';
          this.toastOptions.msg = 'Please try again later.';
          this.toastyService.error(this.toastOptions);
        }
      });
  }

  onViewerDataToAddLesion(objectData: any) {
    const neededObject = this.removeUselessfields(objectData)
    //for assessment handeling
    if (this.assessment || this.mranoEfficacy || this.recistEligibility) {
      this.sendViewerDataByLocalStorage(neededObject)
    }
    else {
      //total volume will be provided manually
      // if (this.totalVolumeCalculation) {
      //   this.toastOptions.title = 'INFO Lesion cannot be added';
      //   this.toastOptions.msg = 'Please measure the total volume from ROI panel to complete the eligibility assessment';
      //   this.toastyService.info(this.toastOptions);
      //   return;
      // }
      this.addObjectForEligibility(neededObject);
    }
    this.editObjectData = null;
  }

  removeUselessfields(objectData: any) {
    return {
      axialLongDiameter: objectData.axialLongDiameter,
      diam: objectData.diam,
      objectLocationX: objectData.objectLocationX,
      objectLocationY: objectData.objectLocationY,
      frameNumber: objectData.frameNumber,
      metadatas: objectData.metadatas,
      objectType: objectData.objectType,
      seriesId: objectData.seriesId,
      shortAxis: objectData.shortAxis,
      sliceNumber: objectData.sliceNumber,
      sopInstanceUid: objectData.sopInstanceUid,
      voi: { name: objectData?.voi?.name, volume: objectData?.voi?.volume }
    }
  }

  addObjectForEligibility(objectData) {
    if (objectData.objectType == '')
      return;
    objectData.objectType = objectData.objectType === 'Ruler' ? MRanoLesionObject.MIDLINESHIFT : MRanoLesionObject.TARGET_LESION;
    if (!this.editObjectData) {
      this.toastOptions.title = 'INFO: CANNOT ADD LESION';
      this.toastOptions.msg = 'Add lesion button must be clicked before this';
      this.toastyService.info(this.toastOptions);
      return
    }
    const message: FormEvent = { key: EventKey.ELIGIBILITY_LESION_DATA, value: objectData };
    this.messagingService.sendRequest(message, FormPage.MRANO);
  }

  sendViewerDataByLocalStorage(objectData?: any) {
    if (!this.addLesionRequestData) {
      this.toastOptions.title = 'ERROR: Lesion type is undefined';
      this.toastOptions.msg = 'Lesion type must be chosen in scoring form';
      this.toastyService.error(this.toastOptions);
      return;
    }
    const sentData: OncologyAddLesionData = {
      viewerData: objectData,
      edit: this.editObjectData?.editMode,
      editType: this.editObjectData?.editType,
      data: this.editObjectData?.currentLesionData,
      requestedData: this.addLesionRequestData
    }
    const message: FormEvent = {
      key: EventKey.ADD_LESION,
      value: sentData,
      destination: this.lastRequest.publisherId
    };
    this.messagingService.sendRequest(message);
    //this.makeSnapshotFromViewerOverWebsocket(this.lastRequest)
    this.addLesionRequestData = null;
    this.editObjectData = null;
  }

  onEditMeasurement(data) {
    // this.isExpanded = false;
    this.editObjectData = data;
  }

  onCancelEditMode() {
    try {
      if (this.newLTViewer.instance.actionManagerService['isAddingMarker']) {
        this.newLTViewer.instance.actionManagerService['isAddingMarker'] = false;
      }
    } catch (error) {

    }
    this.readingMranoService.onEditLesionMeasurement(null);
    this.editObjectData = null;
    this.addLesionRequestData = null;
  }

  preOpenLTViewer() {
    this.openNewLTViewer(null);
  }

  openLTViewer(seriesInstanceUIDs: string[] = [], sliceNumber?: number, locked?: boolean) {
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 1500);

    this.viewerContainer.nativeElement.style.display = '';

    if (this.newLTViewer && this.viewerDataIsReady && this.viewerData) {
      this.viewerData['sliceNumber'] = sliceNumber;
      this.viewerData['readingID'] = this.currentReading.id;
      this.viewerData['isMRano'] = true;
      this.viewerData['locked'] = locked;
      this.newLTViewer.instance.statDatas = this.visitConfig;
      this.newLTViewer.instance.openNewSer(seriesInstanceUIDs, this.viewerData);
      this.openedNumberOfImages = this.newLTViewer.instance.getNumberOfImages();
      return;
    } else {
      this.openNewLTViewer(seriesInstanceUIDs);
    }

    this._updateViewerToolbar();

    const toolbarExcludeItems = _.get(this.viewerData, 'toolbarExcludeItems', []);
    if (toolbarExcludeItems.length > 0) {
      _.each(toolbarExcludeItems, i => {
        this.newLTViewer.instance.viewerToolbar.setItemProperty(i, 'disabled', true);
      });
    }
  }

  closeLTViewerSeries(serID, shouldCloseViewer) {
    let index = this.openedSeriesVisitConfigIds.find((s, i) => {
      if (s.seriesId === serID)
        return i;
    })
    this.openedSeriesVisitConfigIds.splice(index, 1);
    localStorage.setItem('visitConfigIds', JSON.stringify(this.openedSeriesVisitConfigIds));
    this.seriesNavigator.unmarkSeriesAsOpenedBySeriesId(serID);

    if (shouldCloseViewer) {
      this.viewerContainer.nativeElement.style.display = 'none';
    }
  }

  secondsToHHMMSS() {
    const currentSeconds = this.spentSeconds;
    let hours: number | string = Math.floor(currentSeconds / 3600);
    let minutes: number | string = Math.floor((currentSeconds - (hours * 3600)) / 60);
    let seconds: number | string = currentSeconds - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  }

  skipCurrentReadingAndUpdate(readingId?: number) {
    this.selectedTab = 0;
    this.showModalSpinnerLoadingData = true;
    this.currentReading.timeSpent = this.spentSeconds;
    this.readingMranoService.updateReading(
      this.study.id, this.currentReading.id, this.spentSeconds
    ).subscribe(response => {
      if (response['responseCode'] === 200) {
        this.skipCurrentReading(readingId ? this.readingList.findIndex(reading => reading.id === readingId) : this.getIndexOfCurrentReading() + 1);
      } else {
        this.showErrorMessage(response);
      }
    });
  }

  getIndexOfCurrentReading() {
    return this.readingList.indexOf(this.currentReading);
  }

  skipCurrentReading(nextIndex: number, removeCurrent: boolean = false) {
    // hande scoring less
    if (!this.viewerLess) {
      if (this.newLTViewer) {
        this.newLTViewer.instance.removeAllCells();
      }
      this.newLTViewer.instance.removeAllCells();
      this.visitedSeriesCounter = 0;
      if (removeCurrent) {
        const index = this.getIndexOfCurrentReading();
        this.readingList.splice(index, 1);
      }
    }

    if (nextIndex >= this.readingList.length) {  // circle
      nextIndex = 0;
      this.detailsGot = true;
      this.readingMranoService.modalSpinnerChangeState(false);
    }

    // hande viewer less
    if (this.viewerLess) {
      const message: FormEvent = { key: EventKey.SKIP_FORM, value: { index: nextIndex, removeCurrent: removeCurrent } };
      this.messagingService.sendRequest(message, FormPage.MRANO);
      if(this.readingList.length){
        this.router.navigate([],
          {
            relativeTo: this.activatedRoute,
            queryParams: { viewerLess: "true", rid: this.readingList[nextIndex].id }
          })
      }
    }
    if(this.readingList.length)
      this.initReading(this.readingList[nextIndex]);

  }

  resetScoringForms() {
    this.store.dispatch(new SetPageHeaderTitle('Rano with eligibility Scoring Form'));
    if (this.assessment || this.mranoEfficacy) {
      if (this.assessment) {
        this.store.dispatch(new SetPageHeaderTitle('Rano Assessment – Scoring Form'));
        if (this.viewerLess)
          this.activeSelectedTab();
      }
      if (this.mranoEfficacy) {
        this.store.dispatch(new SetPageHeaderTitle('mRano Assessment – Scoring Form'));
        if (this.viewerLess) {
          this.chooseActiveVisitByStatus();
        }
      }
      //get nadir and baseline sppd by reading id
      if (this.viewerLess)
        this.getBaseLineAndHistoricalNadir();
    }
  }

  getBaseLineAndHistoricalNadir() {
    const sources = [
      this.oncologyAssessmentService.getNadirBaselineSPPD(this.study.id, this.currentReading.readingVersion, this.currentReading.id),
      this.oncologyAssessmentService.getHistoricalNadir(this.study.id, this.currentReading.readingVersion, this.currentReading.id)
    ];

    forkJoin(sources).subscribe(values => {
      const [nadirResponse, baselineResponse] = values as any;
      this.oncologyAssessmentService.baselineNadirSPPDChange({
        readingId: this.currentReading.id,
        baselineSPPD: nadirResponse['data'].baselineSppd,
        nadirSPPD: nadirResponse['data'].nadirSppd,
        historicalNadir: baselineResponse['data'],
        nadirVisitConfigId: nadirResponse['data'].nadirVisitConfigId
      });
    }, err => {
      console.error('Failed to get Nadir visit: ', err);
      this.oncologyAssessmentService.baselineNadirSPPDChange({
        readingId: this.currentReading.id,
        baselineSPPD: 'NA',
        nadirSPPD: 'NA',
        historicalNadir: [],
        nadirVisitConfigId: null,
      });
    });
  }

  disableForSmartRead(index: number) {
    if (this.visitConfig[index].noUpload)
      return true;
    if (this.smartRead || index === 0)
      return false;
    return !this.visitConfig[index - 1].lock;
  }

  diableForSequentialLock(index: number) {
    const allVisitExceptNoUploads = this.visitConfig.filter(v => !v.noUpload);
    const currentIndexExceptNoUploads = allVisitExceptNoUploads.findIndex(v => v.id === this.visitConfig[index].id)
    if (this.visitConfig[index].noUpload)
      return true;
    if (!this.sequentialLock || index === 0)
      return false;
    return !allVisitExceptNoUploads[currentIndexExceptNoUploads - 1].lock;
  }

  submitResult() {
    if (this.isReadingValid())
      this.submitAndNexReading()
  }

  checkReadings(index: number) {
    this.readingList.splice(index, 1);
    this.skipCurrentReading(index, true);    // ind == nextIndex after splice
    if (!this.readingList.length) {
      this.currentReading = undefined;
      this.updateEventSubscriptionForCurrentReading();
      this.showModalSpinnerLoadingData = false;
    }
  }

  showErrorMessage(response: any): void {
    this.showModalSpinnerLoadingData = false;
    this.toastOptions.title = 'ERROR ' + response['responseCode'];
    this.toastOptions.msg = response['responseMessage'];
    this.toastyService.error(this.toastOptions);
  }

  isReadingValid() {
    this.validationMessages = [];
    if (!this.assessment && !this.mranoEfficacy) {
      if (this.currentReading && !this.currentReading.historicalScan.lock)
        this.validationMessages.push(`${this.currentReading.visits[0].timepoint} score must be locked to submit reading`);
      if (this.currentReading && !this.currentReading.eligibilityScan.lock) {
        if (this.currentReading.visits.length === 2)
          this.validationMessages.push(`${this.currentReading.visits[1].timepoint} score must be locked to submit reading`);
        else
          this.validationMessages.push(`Second visit score must be locked to submit reading`);
      }

    }
    return !this.validationMessages.length;
  }

  eligibilityChanged(status){
    this.eligibilityStatusSelectedByUser = status;
  }
  scoreLocked(scoringLockData) {
    switch (scoringLockData.type) {
      case MRanoScoringType.HISTORICAL:
        this.currentReading.historicalScan = scoringLockData.data;
        this.copyLesionsToEligibility(scoringLockData.data);
        if (this.currentReading.visits.length < 2)
          this.onHistoricalLock();
        break;
      case MRanoScoringType.ELIGIBILITY:
        this.currentReading.eligibilityScan = scoringLockData.data;
        break;
    }
    this.changeSeriesStatus(scoringLockData.type);
    this.readingMranoService.readingScoreLock(this.study['id'], scoringLockData).subscribe(
      (response) => {
        if (response['responseCode'] === 200) {
          this.toastOptions.title = 'SUCCESS';
          this.toastOptions.msg = 'form has been locked successfully';
          this.toastyService.success(this.toastOptions);
        } else {
          this.toastOptions.title = 'ERROR ' + response['responseCode'];
          this.toastOptions.msg = response['responseMessage'];
          this.toastyService.error(this.toastOptions);
        }
      });
  }
  skipLockAndSubmitReading(data) {
    if (this.recistEligibility) {
      this.currentReading.imageQualityAssessment = data.imageQualityAssessment;
      this.currentReading.imageQualityAssessmentComment = data.imageQualityAssessmentComment;
      this.currentReading.comment = data.generalComment;
      this.currentReading.clinicalData = data.clinicalData;
      this.eligibilityChanged(data.eligibilityStatus);
      this.submitAndNexReading();
    }
  }

  //for assessment visit lock
  onAssessmentVisitLock(data) {
    this.showModalSpinnerLoadingData = true;
    const formData = {
      imageQualityAssessment: data.imageQualityAssessment,
      imageQualityAssessmentComment: data.imageQualityAssessmentComment
    }
    this.oncologyAssessmentService.lockVisitByVisitConfigId(this.study.id, this.currentReading.id, data.visitConfigId, true, formData, data.multiTP).subscribe(response => {
      this.showModalSpinnerLoadingData = false;
      let currentVisitIndex = 0;
      this.visitConfig.forEach((visit, i) => {
        if (visit.visitConfigId === data.visitConfigId) {
          visit.lock = true;
          currentVisitIndex = i
        }
      });
      //check if next visit is available
      const nextAvailableVisitIndex = this.visitConfig.findIndex((v, i) => !v.noUpload && i > currentVisitIndex )
      if (nextAvailableVisitIndex !== -1) {
        this.selectedTab = nextAvailableVisitIndex;
        //referesh lesion list lesion list
        this.oncologyAssessmentService.triggerLesionList(this.visitConfig[nextAvailableVisitIndex].visitConfigId);
        //get nadir/baseline SPPD
        this.resetScoringForms();
      } else {
        this.currentReading.timeSpent = this.spentSeconds;
        this.readingMranoService.updateReading(
          this.study.id, this.currentReading.id, this.spentSeconds
        ).subscribe(response => {
          const index = this.getIndexOfCurrentReading();
          this.readingList.splice(index, 1);
          this.skipCurrentReading(index, true);    // ind == nextIndex after splice
          if (!this.readingList.length){
            //send request to end reading on viewer tab
            const message: FormEvent = { key: EventKey.END_READING, value: true };
            this.messagingService.sendRequest(message, FormPage.MRANO);

            this.currentReading = undefined;
            this.updateEventSubscriptionForCurrentReading();
            this.showModalSpinnerLoadingData = false;
          }
        })
      }
    })
  }

  onEfficacyVisitLock(data) {
    this.oncologyAssessmentService.lockTimepoint(this.study.id, this.currentReading.id, data.visitConfigId).subscribe(response => {
      if (response.responseCode === 200) {
        const uploadedVisit = this.visitConfig.filter(v => v.noUpload === false);
        uploadedVisit.forEach((visit, i) => {
          if (visit.visitConfigId === data.visitConfigId) {
            visit.status = VisitStatus.DONE;
            if (uploadedVisit[i + 1] === undefined)
              return;
            this.oncologyAssessmentService.triggerLesionList(uploadedVisit[i + 1].visitConfigId);
            this.chooseActiveVisitByStatus();
          }
        });
      }
    })
  }

  onHistoricalLock() {
    this.readingMranoService.lockHistoricalReading(this.currentReading.studyId, this.currentReading.id).subscribe(response => {
      if (response['responseCode'] !== 200) {
        this.showErrorMessage(response);
      } else {
        const index = this.getIndexOfCurrentReading();
        this.readingList.splice(index, 1);
        this.skipCurrentReading(index, true);    // ind == nextIndex after splice
        if (!this.readingList.length) {
          this.currentReading = undefined;
          this.updateEventSubscriptionForCurrentReading();
          this.showModalSpinnerLoadingData = false;
        }
      }
    });
  }

  copyLesionsToEligibility(scoringLockData: any) {
    let lesions = scoringLockData.measurableBrain.filter(f => {
      return f.status === 'AAA111'
    }).map(l => {
      return {
        comment: l.comment,
        eligibilityScan: {
          id: this.currentReading.eligibilityScan.id
        },
        historicalScan: null,
        id: null,
        lesionObject: l.lesionObject === MRanoLesionObject.MEASURABLE ? MRanoLesionObject.TARGET_LESION : MRanoLesionObject.NON_TARGET_LESION,
        lesionType: l.lesionType,
        linearMeasurement: l.linearMeasurement,
        locationComment: l.locationComment,
        primaryLocation: l.primaryLocation,
        recordNumber: l.recordNumber.replace('M','T').replace('NM','NT'),
        rois: [],
        status: l.status,
        subLocation: l.subLocation,
        screenShot: null,
        changeLesionType: false,
        type: l.type,
        voiValue: {
          VOIName: null,
          value: null
        }
      }
    });

    let eligibilityData = {
      type: 'ELIGIBILITY',
      data: {
        id: this.currentReading.eligibilityScan.id,
        lock: false,
        lesions: lesions,
        useBevacizumab: this.currentReading.eligibilityScan.useBevacizumab
      }
    }
    if (lesions.length > 0) {
      this.readingMranoService.readingScoreLock(this.study['id'], eligibilityData).subscribe(res => {
        if (this.currentReading.visits.length > 1) {
          this.eligibilityComponent.targetLesionTable.getAllLesion();
          this.eligibilityComponent.nonTargetLesionTable.getAllLesion();
          this.eligibilityComponent.newLesionTable.getAllLesion();
        }
      })
    }
    this.selectedTab++;
  }

  changeSeriesStatus(formType: string) {
    this.seriesStatus = this.seriesStatus.map(element => {
      return [element[0], element[1], element[1] == formType];
    });
  }

  ModalSpinnerChangeState(state: boolean) {
    this.showModalSpinnerLoadingData = state;
  }

  openLTViewerFromChildren(data) {
    if (data['seriesId'] && this.newLTViewer) {
      if (this.newLTViewer.instance.seriesOpened && this.newLTViewer.instance.seriesOpened[data['seriesId']] != null) {
        if (data['sliceNumber'] !== undefined) {
          this.newLTViewer.instance.viewer.layout.get_items().toArray().forEach(cell => {
            if (cell.seriesId === data['seriesId'] || cell.seriesId === data['seriesId'] + '') {
              cell.currentOffset = parseInt(data['sliceNumber'], 10) - 1;
            }
          });
        }
      } else {
        const sliceNumber = data['sliceNumber'] ? data['sliceNumber'] : data['sliceNumber'];
        const seriesId = data['seriesId'];
        this.openLTViewer([seriesId], sliceNumber);
      }
    }
  }

  disableBySequntialLockAndSmartRead(changeStatus: boolean = false, visitIndex: number) {
    const reading = this.currentReading;
    const noUpload = reading.visits[visitIndex].noUpload;
    const PreviouseVisitNotLocked = visitIndex === 0 ? false : reading.visits.findIndex(v => v.status !== VisitStatus.DONE && !v.noUpload) < visitIndex;

    return noUpload ? true : (!this.sequentialLock ? false : (changeStatus ? PreviouseVisitNotLocked :
      ((!this.smartRead &&
        reading.visits[visitIndex].status !== VisitStatus.IN_PROGRESS &&
        reading.visits[visitIndex].status !== VisitStatus.NEW_EDITABLE
       ) ||
        reading.visits[visitIndex].status === VisitStatus.NOT_AVAILABLE ||
        (reading.visits[visitIndex].status !== VisitStatus.DONE &&
        visitIndex > 1 &&
        (reading.visits.findIndex(v => v.status !== VisitStatus.DONE && !v.noUpload) < visitIndex))
      )))
  }

  chooseActiveVisitByStatus() {
    const activeVisitIndex = this.currentReading.visits.findIndex(visit => visit.status === VisitStatus.IN_PROGRESS || visit.status === VisitStatus.NEW_EDITABLE);
    const newNotEditable = this.currentReading.visits.findIndex(visit => visit.status === VisitStatus.NEW_NOT_EDITABLE);
    if (activeVisitIndex !== -1)
      this.onChangeActivatedVisit(activeVisitIndex);
    else if (newNotEditable !== -1){
      this.onChangeActivatedVisit(newNotEditable);
    } else {
      const visits = [...this.currentReading.visits];
      const visit = visits.reverse().find(v => v.status === VisitStatus.DONE)
      this.selectedVisit = this.currentReading.visits.findIndex(v => v.id === visit.id);
    }
  }
  onChangeActivatedVisit(index) {
    this.selectedVisit = index;
    const visit = this.currentReading.visits[index];
    this.updateTimepointStatus(VisitStatus.IN_PROGRESS, visit);
  }
  updateTimepointStatus(status: VisitStatus, visit) {
    if (visit.status === VisitStatus.IN_PROGRESS ||
      visit.status === VisitStatus.DONE ||
      visit.status === VisitStatus.NOT_AVAILABLE) return;

    this.oncologyAssessmentService.updateTimepointStatus(this.currentReading.studyId, this.currentReading.id, visit.visitConfigId, status).subscribe(response => {
      if (response.responseCode === ResponseCode.OK) {
        visit.status = status;
        if (this.mranoEfficacy) {
          this.updateActiveVisitList(visit);
        }
      }
    });
  }

  submitReading(): void {
    const dialogRef = this.dialog.open(VisitsStatusDialogComponent, {
      width: '600px',
      data: this.visitConfig.map(i =>{return {name: i.timepoint, status: i.status}})
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.submitAndNexReading();
      }
    });
  }

  completeEligibilityReading() {
    const dialogRef = this.dialog.open(ConfirmSigningDialogComponent, {
      width: '500px',
      data: {
        title: 'Image Analysis Group requires you to authenticate your signature on this document.',
        studyId: this.study.id,
        patientId: this.currentReading.patientId,
        visitConfigId: this.visitConfig.id
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log('completeEligibilityReading data: ', data);
      if (!data?.jwt) {
        if (!data?.canceled) {
          this.toastService.error('ERROR', 'Sign Report Failed.<br/>Please enter valid credentials');
        }
        this.loading = false;
        this.showModalSpinnerLoadingData = false;
        return;
      }
      this.loading = true;
      this.readingMranoService.completeRecistEligibilityReading(
        this.study.id,
        this.readingId,
        this.currentReading,
        this.eligibilityStatusSelectedByUser,
        data.jwt
      ).subscribe(
        (completeResponse) => {
          if (completeResponse["responseCode"] === 200) {
            const index = this.getIndexOfCurrentReading();
            this.checkReadings(index);
          } else {
            this.showErrorMessage(completeResponse);
          }
        },
        error => {
          this.toastService.respError(error);
          this.loading = false;
        }
      );
    });
  }
  submitAndNexReading() {
    console.log('submitAndNexReading');
    this.currentReading.timeSpent = this.spentSeconds;
    this.showModalSpinnerLoadingData = true;
    if (this.recistEligibility) {
      this.completeEligibilityReading();
    } else {
      this.readingMranoService.completeReading(
        this.study.id,
        this.currentReading,
        this.eligibilityStatusSelectedByUser
      ).subscribe((response) => {
        if (response["responseCode"] === 200) {
          const index = this.getIndexOfCurrentReading();
          this.checkReadings(index);
        } else {
          this.showErrorMessage(response);
        }
      });
    }
    //handle viewer less
    // if (this.viewerLess) {
    //   const message: FormEvent = { key: EventKey.SUBMIT_FORM, value: { index: this.getIndexOfCurrentReading() } };
    //   this.messagingService.sendRequest(message, FormPage.MRANO);
    // }
  }

  checkTimePointValidity(visitIndex: number) {
    return this.currentReading.visits[visitIndex].status === VisitStatus.DONE ||
      this.currentReading.visits[visitIndex].status === VisitStatus.NEW_NOT_EDITABLE ||
      this.currentReading.visits[visitIndex].status === VisitStatus.NOT_AVAILABLE ||
      this.disableBySequntialLockAndSmartRead(true, visitIndex);
  }

  validateSubmit() {
    return this.visitConfig.filter(visit =>  visit.status !== VisitStatus.DONE && !visit.noUpload).length === 0
  }

  updateActiveVisitList(visit: any) {
    try {
      if (visit.status === 'IN_PROGRESS' || visit.status === 'NEW_EDITABLE') {
        if (!this.utils.MRanoActiveVisits.includes(visit.timepoint)) {
          this.utils.MRanoActiveVisits.push(visit.timepoint);
        }
      } else {
        if (this.utils.MRanoActiveVisits.includes(visit.timepoint)) {
          const index: number = this.utils.MRanoActiveVisits.indexOf(visit.timepoint);
          if (index !== -1) {
            this.utils.MRanoActiveVisits.splice(index, 1);
          }
        }
      }
    } catch (error) {
      console.log('error... ', error);
    }
  }

  updateTransformedPatientClinicalData() {
    this.cdr.detectChanges(); // Ensure Angular updates the binding
  }
}
