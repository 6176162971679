import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-mrano-upload-clinical-data-dialog',
  templateUrl: './mrano-upload-clinical-data-dialog.component.html',
  styleUrls: ['./mrano-upload-clinical-data-dialog.component.css']
})
export class MranoUploadClinicalDataDialogComponent {
  selectedFile: File | null = null;
  fileError: string | null = null;

  constructor(public dialogRef: MatDialogRef<MranoUploadClinicalDataDialogComponent>) {}

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const fileName = file.name.toLowerCase();

      // Check if the file is a CSV
      if (!fileName.endsWith('.csv')) {
        this.fileError = 'The uploaded file must be a CSV.';
        this.selectedFile = null;
        return;
      }

      // File is a CSV, proceed with structure validation
      this.selectedFile = file;
      this.validateFileStructure(file);
    } else {
      this.selectedFile = null;
      this.fileError = 'Please select a file.';
    }
  }

  validateFileStructure(file: File) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target?.result as string;
      const rows = content.split('\n');
      if (rows.length > 0 && rows[0].split(',').length !== 2) {
        this.fileError = 'The uploaded CSV file must contain exactly 2 columns: PatientCode and ClinicalData.';
        this.selectedFile = null; // Invalidate file selection
      } else {
        this.fileError = null; // Validation passed
      }
    };
    reader.readAsText(file);
  }

  submitFile() {
    if (this.selectedFile && !this.fileError) {
      this.dialogRef.close(this.selectedFile); // Return valid file
    } else {
      this.dialogRef.close(null); // Close without returning a file
    }
  }

  cancel() {
    this.dialogRef.close(null); // Close dialog without returning any data
  }
}
