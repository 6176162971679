import { ReadingMranoService } from 'src/app/_services/reading-mrano.service';
import { Component, OnInit } from '@angular/core';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {MatDialog} from '@angular/material/dialog';
import {UploadDialogComponent} from '../../DataUpload/upload-dialog/upload-dialog.component';
import { FormControl, Validators } from '@angular/forms';
import { OncologyAssessmentService } from 'src/app/_services/oncology-assessment.service';

@Component({
  selector: 'app-imaging-project-criteria',
  templateUrl: './imaging-project-criteria.component.html',
  styleUrls: ['./imaging-project-criteria.component.css']
})

export class ImagingProjectCriteriaComponent {
  formData: any;
  jasonFileName = '';
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  criteriaField = new FormControl('', [
    Validators.required
  ]);

  constructor(private oncologyAssessmentService: OncologyAssessmentService,
              private toastyService: ToastyService,
              public dialog: MatDialog,
              ) { }

  selectFiles(jasonFile: any) {
      if (jasonFile.target.files && jasonFile.target.files[0]) {
        this.jasonFileName = '';
        Array.from(jasonFile.target.files).forEach((file: File) => {
          this.jasonFileName += file.name;
          this.formData = jasonFile.target.files[0];
        });
      }
    }
  
  uploadJsonFileToCloud() {
      this.openDialog();
      if (this.formData) {
        const request = new FormData();
        request.append('file', this.formData);
        const project = this.criteriaField.value;
        this.oncologyAssessmentService.uploadCriteriaFile(project, request).subscribe((response: any) => {
          if(response !== null && response.responseCode === 200) {
            this.dialog.closeAll();
            this.toastOptions.title = 'Success';
            this.toastOptions.msg = 'Criteria manual file successfully uploded';
            this.toastyService.success(this.toastOptions);
          } else {
            this.dialog.closeAll();
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
          }, (error) => {
            this.dialog.closeAll();
            this.toastOptions.title = error.statusText;
            this.toastyService.error(this.toastOptions);
          });
      }
    }

    openDialog(): void {
     this.dialog.open(UploadDialogComponent, {
        height: '400px',
        width: '600px',
        disableClose: true,
        data: {files : [this.formData]}
      });
    }
}

