import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MranoLesionModel} from 'src/app/_models/MRANO/mrano-lesion-model';
import {ReadingMranoService} from 'src/app/_services/reading-mrano.service';
import {MRanoLesionEditType, MRanoLesionObject} from 'src/app/_models/MRANO/mrano-scoring-model';
import {LeadToolsActionManagerService, PrimaryBodyLocationService} from 'src/app/_services';
import { interval, Observable, Subscription } from 'rxjs';
import { finalize, map, take, takeWhile } from "rxjs/operators";
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {EventKey, FormEvent} from 'src/app/_models/Oncology/local-storage-keys.model';
import {FormPage, MessagingService} from "../../../_services/messaging.service";

export interface ModifiedViewerData {
  lesionObject: MRanoLesionObject,
  voi?: any,
  rois?: any,
  linearMeasurement?: number,
  sliceNumber?: number,
  seriesId?: number
}

export interface LesionDialogFormData {
  //recordNumber:string,
  studyId: number,
  readingId: number,
  editDialog: boolean,
  lesionObject: MRanoLesionObject,
  editType: string,
  lesionData: any,
  screenShotData: any,
  viewerData?: any,
  eCRFOnly: boolean,
  viewerLess: boolean,
  locationEnabled: boolean,
  WPD: boolean
}

@Component({
  selector: 'app-lesion-dialog-form',
  templateUrl: './lesion-dialog-form.component.html',
  styleUrls: ['./lesion-dialog-form.component.css'],
  providers: [MessagingService]
})
export class LesionDialogFormComponent implements OnInit, OnDestroy {
  @ViewChild('primaryLocation') primaryLocation: MatSelect;
  lesionObject = MRanoLesionObject;

  public get lesionEditType(): typeof MRanoLesionEditType {
    return MRanoLesionEditType;
  }

  minimized: boolean = false;
  showModalSpinnerLoadingData = false;
  lesionData: MranoLesionModel;
  imageUrl: string = '';
  linkData: any;
  imageAttached: boolean = false;
  attachMessage: string;
  modifiedViewerData: ModifiedViewerData;
  maxRoi;
  linear;
  primaryLocations = [];
  subLocations = [];
  userId: number;
  loadingScreenshot = false
  timer$: Observable<string>;
  locationSubscription: Subscription;
  //localstorage
  eventSubscription: Subscription;

  constructor(public dialogRef: MatDialogRef<LesionDialogFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: LesionDialogFormData,
              private readingMranoService: ReadingMranoService,
              private primaryBodyLocation: PrimaryBodyLocationService,
              private leadtoolsActionManagerService: LeadToolsActionManagerService,
              private messagingService: MessagingService
  ) { }
    LesionForm:FormGroup = new FormGroup({});

  ngOnInit(): void {
    if (this.data.viewerData)
      this.mapToModifiedViewerData(this.data.viewerData);
    this.userId = +JSON.parse(localStorage.getItem('userId'));
    this.initLesionForm()
    this.getLocations();
    console.log(this.data);

    const userId = +JSON.parse(localStorage.getItem('userId'));
    this.eventSubscription = this.messagingService.init(userId, this.data.readingId, FormPage.ONCOLOGY_LESION_DIALOG)
      .subscribe(this.handleEvents.bind(this))

    if (!this.data.editDialog || (this.data.editType === this.lesionEditType.MEASUREMENT || this.data.editType === this.lesionEditType.SCREENSHOT))
      this.attachImage();


  }

  //handling local storage event
  handleEvents(event: FormEvent) {
    if (!event.value)
      return;
    switch (event.key) {
      case EventKey.SCREENSHOT_DATA:
        if (event.value) {
          this.setScreenShotToImgFromWebsocket(event.value);
        }
        break;
      default:
        break;
    }
  }

  minimizeDialog() {
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.add('minimized')
    let top = ((dialog[0] as HTMLElement).offsetTop + 60) + 'px';
    document.documentElement.style.setProperty('--dialog-display', "translate3d(0 , calc(100vh - " + top + ") , 0)");
    document.documentElement.style.setProperty('--dialog-background', "#e99425");
    document.documentElement.style.setProperty('--dialog-color', "#fff");
    this.minimized = true;
  }

  maximizeDialog() {
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.remove('minimized')
    document.documentElement.style.setProperty('--dialog-display', "");
    document.documentElement.style.setProperty('--dialog-background', "#fff");
    document.documentElement.style.setProperty('--dialog-color', "");
    this.minimized = false;
  }

  mapToModifiedViewerData(viewerData) {
    console.log(viewerData)
      this.modifiedViewerData = {
        lesionObject :viewerData.objectType
      }
      if(viewerData.objectType === this.lesionObject.MIDLINESHIFT) {
        let MIDLINESHIFTDiam = 0;
        let MIDLINESHIFTSliceNumber = 0;
        let MidlineShiftFailed: boolean = false;
        try {
          MIDLINESHIFTDiam = +viewerData.metadatas[0].RulerLength.slice(0, -3);
          MIDLINESHIFTSliceNumber = +viewerData.metadatas[0].RulerLength.slice(0, -3);
        } catch (error) {
          MidlineShiftFailed = true;
          console.log('Error... ', error);
        }
        try {
          if (MIDLINESHIFTDiam === 0 || MIDLINESHIFTSliceNumber === 0 || MidlineShiftFailed) {
            MIDLINESHIFTDiam = parseFloat(viewerData.diam);
            MIDLINESHIFTSliceNumber = parseFloat(viewerData.sliceNumber);
          }
        } catch (error) {
          console.log('Error... ', error);
        }

        this.modifiedViewerData = {
          lesionObject :viewerData.objectType,
          linearMeasurement : MIDLINESHIFTDiam,
          sliceNumber: MIDLINESHIFTSliceNumber,
          seriesId:+viewerData.seriesId,
          rois:viewerData.metadatas.map(data => {
            return{
              id:null,
              name: data.Name,
              area: data.Area,
              sliceNumber: data.SliceNumber-1,
              seriesId: viewerData.seriesId,
              biDimension: {
                Long: 0,
                Short: 0
               }
            }
          })
        }
      }
      else{
        this.modifiedViewerData = {
          lesionObject :viewerData.objectType,
          seriesId:viewerData.seriesId,
          voi:{
            name: viewerData.voi.name ? viewerData.voi.name : '',
            volume: viewerData.voi.volume ? viewerData.voi.volume : 0
          },
          rois: viewerData.metadatas.map(data => {
            let shortAxis = data.FirstLineLength?.slice(0, -3) < data.SecondLineLength?.slice(0, -3) ? data.FirstLineLength?.slice(0, -3) : data.SecondLineLength?.slice(0, -3);
            let longAxis = data.FirstLineLength?.slice(0, -3) > data.SecondLineLength?.slice(0, -3) ? data.FirstLineLength?.slice(0, -3) : data.SecondLineLength?.slice(0, -3);
            if (shortAxis === undefined || longAxis === undefined) {
              try {
                longAxis = parseFloat(viewerData.axialLongDiameter);
                shortAxis = parseFloat(viewerData.shortAxis);
              } catch (error) {
                console.log('Error... ', error);
              }
            }
            return{
              id:null,
              name: data.Name,
              area: longAxis * shortAxis,
              sliceNumber: data.SliceNumber-1,
              seriesId: viewerData.seriesId,
              biDimension: {
                Long: longAxis,
                Short: shortAxis
               }
            }
          }),
        }
        //add cross ruler to rois
        if (viewerData.metadatas.CrossRulerName) {
          this.modifiedViewerData.rois.push({
              id:null,
              name: viewerData.metadatas?.CrossRulerName,
              area: 0,
              sliceNumber: viewerData.SliceNumber-1,
              seriesId: viewerData.seriesId,
              biDimension: {
                Long: 0,
                Short: 0
              }
          })
        }
      }
      console.log(this.modifiedViewerData)
  }

  getLocations() {
    this.showModalSpinnerLoadingData=true;
    this.locationSubscription = this.primaryBodyLocation.findAll().subscribe(
      (response)=>{
        if(response.responseCode ==200){
          this.primaryLocations = response.data;
          const brainId = this.primaryLocations.find(l => l.name.toLowerCase().trim() === 'brain').id
          this.LesionForm.get('primaryLocationField').setValue(brainId);
          this.LesionForm.get('primaryLocationField').updateValueAndValidity();
          this.subLocations = this.primaryLocations.filter(item => { return item.id === brainId }).map(item => { return item.bodySubLocations })[0];
          if(this.data.editDialog)
            this.onEditForm();
          this.showModalSpinnerLoadingData=false;
        }
      }
    )
  }

  initLesionForm() {
    if(this.modifiedViewerData){
      this.maxRoi= this.calculateMaxBidimention();
      this.linear= this.modifiedViewerData.lesionObject == "MIDLINESHIFT" ? this.modifiedViewerData.linearMeasurement:null;
    }
    this.LesionForm = new FormGroup({
      id: new FormControl(null),
      lesionObjectTypeField: new FormControl({
        value: this.data.lesionObject,
        disabled: true
      }, [Validators.required]),
      recordNumberField: new FormControl({value: '', disabled: true}, [Validators.required]),
      shortAxisField: new FormControl({
        value: this.maxRoi?.biDimension['Short'],
        disabled: !this.data.eCRFOnly
      }, [Validators.pattern('([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)')]),
      longAxisField: new FormControl({
        value: this.maxRoi?.biDimension['Long'],
        disabled: !this.data.eCRFOnly
      }, [Validators.pattern('([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)')]),
      linearMeasurementField: new FormControl({
        value: this.linear,
        disabled: !this.data.eCRFOnly
      }, [Validators.pattern('([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)')]),
      primaryLocationField: new FormControl('', [Validators.required]),
      subLocationField: new FormControl('', [Validators.required]),
      locationCommentField: new FormControl(''),
      commentField: new FormControl(''),
      typeField: new FormControl('MEASURABLE', [Validators.required]),
      lesionSliceIdField: new FormControl(0),
      lesionSeriesIdField: new FormControl(''),
      screenshotId: new FormControl(null),
      storageFileName: new FormControl(''),
      storageFileNamePreview: new FormControl(''),
      storagePath: new FormControl(''),
      fileUrl: new FormControl(''),
      fileUrlPreview: new FormControl(''),
      bucketName: new FormControl(''),
      modalityName: new FormControl(''),
      sequenceLabelName: new FormControl(''),
      sliceNumber: new FormControl(''),
      changeLesionType: new FormControl(false),
      VOIName:new FormControl(this.modifiedViewerData?.voi?.name),
      value:new FormControl({value:this.modifiedViewerData?.voi?.volume,disabled:!this.data.eCRFOnly},[Validators.pattern('([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)')])
    })
    this.checkMeasurementValidators();
  }
  onEditForm(){
      this.LesionForm.patchValue({
        'id':this.data.lesionData.id,
        'lesionObjectTypeField':this.data.lesionData.lesionObject,
        'recordNumberField':this.data.lesionData.recordNumber,
        'shortAxisField':this.maxRoi? this.maxRoi.biDimension['Short']: this.data.lesionData.maxBiDimension?.Short,
        'longAxisField':this.maxRoi? this.maxRoi.biDimension['Long']:this.data.lesionData.maxBiDimension?.Long,
        'linearMeasurementField':this.linear? this.linear:this.data.lesionData.linearMeasurement,
        'primaryLocationField':+this.data.lesionData.primaryLocation,

        'locationCommentField':this.data.lesionData.locationComment,
        'commentField':this.data.lesionData.comment,
        'typeField':this.data.lesionData.type,
        'lesionSliceIdField':this.modifiedViewerData?this.modifiedViewerData.rois[0].sliceNumber:this.data.lesionData.sliceNumber,
        'lesionSeriesIdField':this.modifiedViewerData?this.modifiedViewerData.seriesId :this.data.lesionData.rois[0]?.seriesId,
        'VOIName':this.modifiedViewerData?this.modifiedViewerData.voi?.name:this.data.lesionData.voiValue?.VOIName,
        'value': this.modifiedViewerData ? this.modifiedViewerData.voi?.volume : this.data.lesionData.voiValue?.value,
        'changeLesionType': this.data.lesionData.changeLesionType
      })
      this.subLocations = this.primaryLocations.filter(item=>{return item.id == this.data.lesionData.primaryLocation }).map(item =>{return item.bodySubLocations})[0];
      this.LesionForm.patchValue({
        'subLocationField':+this.data.lesionData.subLocation,
      })
      if(this.data.lesionData.screenShot){
        this.LesionForm.patchValue({
          'screenshotId':this.data.lesionData.screenShot.id,
        'storageFileName':this.data.lesionData.screenShot.storageFileName,
        'storageFileNamePreview':this.data.lesionData.screenShot.storageFileNamePreview,
        'storagePath':this.data.lesionData.screenShot.storagePath,
        'fileUrl':this.data.lesionData.screenShot.fileUrl,
        'fileUrlPreview':this.data.lesionData.screenShot.fileUrlPreview,
        'bucketName':this.data.lesionData.screenShot.bucketName,
        'modalityName':this.data.lesionData.screenShot.modalityName,
        'sequenceLabelName':this.data.lesionData.screenShot.sequenceLabelName,
        'sliceNumber':this.data.lesionData.screenShot.sliceNumber,
      })
      this.imageUrl = this.data.lesionData.screenShot.fileUrl
      }
  }
  calculateMaxBidimention(){
    if(this.modifiedViewerData){
      let maxArea =Math.max.apply(Math, this.modifiedViewerData.rois?.map(function(r) { return r['area']; }))
      return this.modifiedViewerData.rois?.filter(roi => roi['area']==maxArea)[0]
    }
    return;
  }
  checkMeasurementValidators(){
    if(this.LesionForm.get('lesionObjectTypeField').value =='MIDLINESHIFT'){
      this.LesionForm.get('primaryLocationField').clearValidators();
      this.LesionForm.get('primaryLocationField').updateValueAndValidity();
      this.LesionForm.get('subLocationField').clearValidators();
      this.LesionForm.get('subLocationField').updateValueAndValidity();
      this.LesionForm.get('shortAxisField').clearValidators();
      this.LesionForm.get('shortAxisField').updateValueAndValidity();
      this.LesionForm.get('longAxisField').clearValidators();
      this.LesionForm.get('longAxisField').updateValueAndValidity();
      this.LesionForm.get('value').clearValidators();
      this.LesionForm.get('value').updateValueAndValidity();
    }
    else{
      this.LesionForm.get('linearMeasurementField').clearValidators();
      this.LesionForm.get('linearMeasurementField').updateValueAndValidity();
    }
  }
  onChangeTypeField(event){
    switch (event.value) {
      case "MEASURABLE":
        this.LesionForm.get('shortAxisField').setValue(this.maxRoi?.biDimension['Short'])
        this.LesionForm.get('longAxisField').setValue(this.maxRoi?.biDimension['Long'])
        this.LesionForm.get('shortAxisField').setValidators([Validators.pattern('([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)')])
        this.LesionForm.get('shortAxisField').updateValueAndValidity();
        this.LesionForm.get('longAxisField').setValidators([Validators.pattern('([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)')])
        this.LesionForm.get('longAxisField').updateValueAndValidity();
        this.LesionForm.get('value').setValue(this.modifiedViewerData?.voi?.volume);
        break;
      case "NOT_EVALUABLE":
        this.LesionForm.get('shortAxisField').clearValidators();
        this.LesionForm.get('shortAxisField').updateValueAndValidity();
        this.LesionForm.get('longAxisField').clearValidators();
        this.LesionForm.get('longAxisField').updateValueAndValidity();
        this.LesionForm.get('shortAxisField').setValue(null)
        this.LesionForm.get('longAxisField').setValue(null)
        this.LesionForm.get('value').clearValidators();
        this.LesionForm.get('value').updateValueAndValidity();
        this.LesionForm.get('value').setValue(null);
        break;
      case "TOO_SMALL_TO_MEASURE":
        this.LesionForm.get('shortAxisField').clearValidators();
        this.LesionForm.get('shortAxisField').updateValueAndValidity();
        this.LesionForm.get('longAxisField').clearValidators();
        this.LesionForm.get('longAxisField').updateValueAndValidity();
        this.LesionForm.get('shortAxisField').setValue(5);
        this.LesionForm.get('longAxisField').setValue(5);
        this.LesionForm.get('value').clearValidators();
        this.LesionForm.get('value').updateValueAndValidity();
        this.LesionForm.get('value').setValue(null);
        break;
      case "DISAPPEARED":
        this.LesionForm.get('shortAxisField').clearValidators();
        this.LesionForm.get('shortAxisField').updateValueAndValidity();
        this.LesionForm.get('longAxisField').clearValidators();
        this.LesionForm.get('longAxisField').updateValueAndValidity();
        this.LesionForm.get('shortAxisField').setValue(0);
        this.LesionForm.get('longAxisField').setValue(0);
        this.LesionForm.get('value').clearValidators();
        this.LesionForm.get('value').updateValueAndValidity();
        this.LesionForm.get('value').setValue(0);
        break;

    }
  }
  onLocationChange(event: MatSelectChange){
    if(event.source.triggerValue ==='Other'){
      this.LesionForm.get('locationCommentField').setValidators(Validators.required);
      this.LesionForm.get('locationCommentField').updateValueAndValidity();
    }
    else{
      this.LesionForm.get('locationCommentField').clearValidators();
      this.LesionForm.get('locationCommentField').updateValueAndValidity();
    }
    this.subLocations = this.primaryLocations.filter(item=>{return item.id === event.value }).map(item =>{return item.bodySubLocations})[0];
  }
  onSubLocationChange(event){
    if(event.source.triggerValue ==='Other'){
      this.LesionForm.get('locationCommentField').setValidators(Validators.required);
      this.LesionForm.get('locationCommentField').updateValueAndValidity();
    }
    else{
      this.LesionForm.get('locationCommentField').clearValidators();
      this.LesionForm.get('locationCommentField').updateValueAndValidity();
    }
  }

  // OnChangeAxis(){
  //   let longAxis = +this.LesionForm.get('longAxisField').value;
  //   let shortAxis =+this.LesionForm.get('shortAxisField').value;
  //   if(longAxis <10)
  //     this.LesionForm.get('longAxisField').reset();
  //     if(shortAxis <10)
  //     this.LesionForm.get('shortAxisField').reset();
  // }
  attachImage() {
    let message: FormEvent = { key: EventKey.SCREENSHOT_REQUEST, value: ' ' };
    this.messagingService.sendRequest(message, FormPage.MRANO);
    this.enableCountDown();
  }
  private enableCountDown() {
    this.loadingScreenshot = true;
    const stepsInOneSec = 10;
    const time = 10 * stepsInOneSec // 10 seconds
    let period = 1000 / stepsInOneSec;
    this.timer$ = interval(period) // 1000 = 1 second
      .pipe(
        take(time),
        takeWhile(_ => this.loadingScreenshot),
        map((v) => ((time - 1) - v) / stepsInOneSec), // to reach zero
        map(v => v.toFixed(2)),
        finalize(() => this.loadingScreenshot = false)
      )
    // .subscribe((v) => console.log('Countdown: ', v))

  }

  setScreenShotToImgFromWebsocket(snapshot: any) {
    this.data.screenShotData = {
      seriesId: snapshot.cellInfo.seriesId,
      image: snapshot.image,
      preview: snapshot.image,
      sopInstanceUid: snapshot.cellInfo.seriesUID,
      sliceNumber: snapshot.sliceNum + 1,
      modalityName: snapshot.cellInfo.modality,
      sequenceLabelName: snapshot.cellInfo.label
    }
    this.imageUrl = this.data.screenShotData.image;
    this.imageAttached = true;
    this.attachMessage = "";
    this.loadingScreenshot = false;
  }
  setScreenShotToImg(snapshot:any){
    this.data.screenShotData ={
      seriesId : snapshot.cellInfo.seriesId,
      image :snapshot.image,
      preview:snapshot.image,
      seriesUID : snapshot.cellInfo.seriesUID,
      sliceNumber:snapshot.sliceNum,
      modalityName:snapshot.cellInfo.modality,
      sequenceLabelName:snapshot.cellInfo.label,
    }
    this.imageUrl =this.data.screenShotData.image;
    this.imageAttached= true;
    this.attachMessage="";
  }
  // onVOISelect(VOIData){
  //     this.LesionForm.get('VOIName').setValue(VOIData.VOIName)
  //     this.LesionForm.get('value').setValue(VOIData.value)
  // }
  saveImageToCloud(screenShotData) {
    this.showModalSpinnerLoadingData = true;
    // convert base64 string to Blob object ( array of 8 bit integers)
    const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
      const byteCharacters = window.atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    };
    const blob = b64toBlob(screenShotData.image.split(',')[1], 'image/jpeg');
    const blobPreview = b64toBlob(screenShotData.preview.split(',')[1], 'image/jpeg');
    const ifSliceNum = screenShotData.sliceNumber;
    this.readingMranoService
      .getLesionImageUploadLink(this.data.studyId, this.data.readingId,screenShotData.seriesId, ifSliceNum).subscribe(linkResp => {
      this.linkData = linkResp['data'];
      // fake input form with data
      const fdImage = new FormData();
      fdImage.append('file', blob, 'filename.jpg');
      const fdImagePreview = new FormData();
      fdImage.append('file', blobPreview, 'filename_preview.jpg');
      this.readingMranoService.uplodFileToCloud(this.linkData.fileUploadUrl, blob).subscribe(imgaeUploadRes => {
        this.readingMranoService.uplodFileToCloud(this.linkData.fileUploadUrlPreview, blobPreview).subscribe(imgaePreviewUploadRes => {
          this.lesionData.screenShot.seriesId=this.data.screenShotData.seriesId;
          //this.lesionData.sliceNumber= this.data.screenShotData.sliceNumber+1;
          this.lesionData.screenShot.sliceNumber=this.data.screenShotData.sliceNumber;
          this.lesionData.screenShot.storageFileName =  this.linkData['storageFileName'];
          this.lesionData.screenShot.storageFileNamePreview=this.linkData['storageFileNamePreview'];
          this.lesionData.screenShot.bucketName = this.linkData['bucketName'];
          this.lesionData.screenShot.storagePath = this.linkData['storagePath'];
          this.lesionData.screenShot.fileUrl =this.linkData['fileUrl'];
          this.lesionData.screenShot.fileUrlPreview=this.linkData['fileUrlPreview'];
          this.lesionData.screenShot.modalityName = this.data.screenShotData.modalityName;
          this.lesionData.screenShot.sequenceLabelName = this.data.screenShotData.sequenceLabelName;
          this.showModalSpinnerLoadingData = false;
         // console.log(this.lesionData)
         this.dialogRef.close(this.lesionData)
        });
      });
    });
  }
  OnSave(){
    this.lesionData = {
      id : this.LesionForm.get('id').value,
      recordNumber : this.LesionForm.get('recordNumberField').value,
      lesionObject: this.data.lesionObject,
      maxBiDimension:{
        Short:this.LesionForm.get('shortAxisField').value,
        Long:this.LesionForm.get('longAxisField').value
      },
      voiValue:{
        VOIName:this.LesionForm.get('VOIName').value,
        value:this.LesionForm.get('value').value
      },
      primaryLocation: this.LesionForm.get('primaryLocationField').value,
      subLocation: this.LesionForm.get('subLocationField').value,
      locationComment:this.LesionForm.get('locationCommentField').value,
      comment:this.LesionForm.get('commentField').value,
      status:"AAA111",
      lesionType:this.LesionForm.get('typeField').value,
      sliceNumber:this.LesionForm.get('lesionSliceIdField').value,
      linearMeasurement: this.LesionForm.get('linearMeasurementField').value,
      changeLesionType: this.LesionForm.get('changeLesionType').value,
      historicalScan:{
        id:null,
        imageQualityAssessment:null,
        imageQualityAssessmentComment:null,
        lock:null,
        reading: null,
        sumPPD: null,
        totalVolume:null
      },
      eligibilityScan:{
        id:null,
        diseaseLocationAssessment:null,
        diseaseLocationAssessmentComment:null,
        imageQualityAssessment:null,
        imageQualityAssessmentComment:null,
        lock:null,
        midlineShiftAssessment:null,
        massEffectAssessment:null,
        reading: null,
        sumPPD: null,
        totalVolume: null,
        changeSPPD: null
      },
      screenShot:{
        id:this.LesionForm.get('screenshotId').value,
        storageFileName:this.LesionForm.get('storageFileName').value,
        storageFileNamePreview: this.LesionForm.get('storageFileNamePreview').value,
        storagePath: this.LesionForm.get('storagePath').value,
        fileUrl:this.LesionForm.get('fileUrl').value,
        fileUrlPreview:this.LesionForm.get('fileUrlPreview').value,
        bucketName: this.LesionForm.get('bucketName').value,
        modalityName:this.LesionForm.get('modalityName').value,
        sequenceLabelName:this.LesionForm.get('sequenceLabelName').value,
        sliceNumber:+this.LesionForm.get('sliceNumber').value,
        seriesId:+this.LesionForm.get('lesionSeriesIdField').value
       },
       type:this.LesionForm.get('typeField').value,
       rois:this.modifiedViewerData?this.modifiedViewerData?.rois:this.data.lesionData?.rois
      }
      if(this.data.eCRFOnly && !this.data.editDialog){
        let longAxis = +this.LesionForm.get('longAxisField').value;
        let shortAxis =+this.LesionForm.get('shortAxisField').value;
        this.lesionData.rois=[{
          id:null,
          name: 'eCRF',
          sliceNumber: 0,
          seriesId: 0,
          biDimension: {
            Long: longAxis,
            Short: shortAxis
          }
        }]
      }
      switch(this.lesionData.type){
        case 'DISAPPEARED':
          this.lesionData.rois=[{
            id:null,
            name: 'DISAPPEARED',
            sliceNumber: this.data.eCRFOnly?0:+this.LesionForm.get('sliceNumber').value,
            seriesId: this.data.eCRFOnly?0:+this.LesionForm.get('lesionSeriesIdField').value,
            biDimension: {
              Long: 0,
              Short: 0
             }
        }];
        break;
        case 'NOT_EVALUABLE':
          this.lesionData.rois=[{
            id:null,
            name: 'NOT_EVALUABLE',
            sliceNumber: this.data.eCRFOnly?0:+this.LesionForm.get('sliceNumber').value,
            seriesId: this.data.eCRFOnly?0:+this.LesionForm.get('lesionSeriesIdField').value,
            biDimension: {
              Long: null,
              Short: null
             }
        }];
          break;
        case 'TOO_SMALL_TO_MEASURE':
          this.lesionData.rois = [{
            id: null,
            name: 'TOO_SMALL_TO_MEASURE',
            sliceNumber: this.data.eCRFOnly ? 0 : +this.LesionForm.get('sliceNumber').value,
            seriesId: this.data.eCRFOnly ? 0 : +this.LesionForm.get('lesionSeriesIdField').value,
            biDimension: {
              Long: 5,
              Short: 5
            }
          }];
          break;
      }
    // console.log(this.lesionData)
    // return;
    if (this.imageUrl == '')
      this.lesionData.screenShot = null;
    if (this.imageAttached)
      this.saveImageToCloud(this.data.screenShotData);
    else
      this.dialogRef.close(this.lesionData)
  }

  isInvalid() {
    let invalid = false;
    if (!this.LesionForm.valid || (!this.LesionForm.dirty && this.data.editType !== this.lesionEditType.MEASUREMENT))
       invalid = true;
    if (!this.data.editDialog && !this.imageAttached)
      invalid = true;
    if (this.data.editDialog && this.data.editType == this.lesionEditType.MEASUREMENT && !this.imageAttached)
      invalid = true;
    return invalid;
  }

  closeClick() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.locationSubscription)
      this.locationSubscription.unsubscribe();

    if (this.eventSubscription)
      this.eventSubscription.unsubscribe();
  }
}
