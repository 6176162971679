import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { BasicResponse } from '../core/interfaces/basic-response';
import { SequenceLabelModel } from '../_models/ImagingProject/sequence-label-model';
import { ModalityModel } from '../_models/ImagingProject/modality-model';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class StudySequenceLabelService {

  constructor(private http: HttpClient) {
  }

  create(studySequenceLabel: any) {
    return this.http.post(requestURL + `/study-sequence-label`, studySequenceLabel);
  }

  update(studySequenceLabel: any) {
    return this.http.put(requestURL + `/study-sequence-label`, studySequenceLabel);
  }

  delete(id: number) {
    return this.http.delete(requestURL + `/study-sequence-label/` + id);
  }

  getById(id: number) {
    return this.http.get(requestURL + `/study-sequence-label/` + id);
  }

  getBySeriesId(seriesId: number) {
    return this.http.get(requestURL + `/study-sequence-label/get-labels-by-series-id/` + seriesId);
  }

  getStudySeriesBySeriesIds(seriesIds: number[]): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(requestURL + '/series/get-series-by-ids/without-rel', seriesIds);
  }

  getStudySeriesAndParentsBySeriesIds(seriesIds: number[]): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(requestURL + '/series/get-series-parents-by-ids/without-rel', seriesIds);
  }

  getStudySeriesAndvisitConfigBySeriesIds(seriesIds: number[]): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(requestURL + '/series/get-series-by-ids/', seriesIds);
  }

  getByStudyId(studyId: number) { // PM
    return this.http.get(requestURL + `/study-sequence-label/get-labels-by-study-id/` + studyId);
  }

  getSequenceLabelsByStudyId(studyId: number): Observable<BasicResponse<SequenceLabelModel[]>> { // PM, QC
    return this.http
      .get<BasicResponse<SequenceLabelModel[]>>(requestURL + `/study-sequence-label/get-sequence-labels-by-study-id/` + studyId);
  }

  getStudyModalitiesByStudyId(studyId: number): Observable<BasicResponse<any>> { // Reader, Site, PM
    return this.http.get<BasicResponse<any>>(requestURL + `/study-sequence-label/get-study-modalities-by-study-id/` + studyId);
  }

  getGroupedStudyModalitiesByStudyId(studyId: number): Observable<BasicResponse<ModalityModel[]>> { // Reader, QC
    return this.http
      .get<BasicResponse<ModalityModel[]>>(requestURL + `/study-sequence-label/get-grouped-study-modalities-by-study-id/` + studyId);
  }

  getSequenceLabelsByStudyIdAndModalityId(studyId: number, modalityId: number) {
    return this.http.get(`${requestURL}/study-sequence-label/get-sequence-labels-by-study-id-and-modality-id/${studyId}/${modalityId}`);
  }

  getAll() {
    return this.http.get(requestURL + `/study-sequence-label/retrieve-all`);
  }

  createAll(studySequenceLabels: any[]) {
    return this.http.post(requestURL + `/study-sequence-label/save-all`, studySequenceLabels);
  }
}
