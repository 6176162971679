import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PrimaryBodyLocationService} from 'src/app/_services';
import {interval, Subscription} from 'rxjs';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {EventKey, FormEvent, SNAPSHOT_RAW_DATA} from 'src/app/_models/Oncology/local-storage-keys.model';
import {
  GlobalLesionModel,
  MarkerType,
  MranoEfficacyStatusOptions,
  OncologyLesionEditType,
  OncologyLesionType,
  Snapshot,
} from 'src/app/_models/Oncology/global-lesion-model';
import {OncologyAssessmentService} from 'src/app/_services/oncology-assessment.service';
import {FormPage, MessagingService} from "src/app/_services/messaging.service";
import {finalize, map, take, takeWhile} from "rxjs/operators";
import { Observable } from "rxjs/src/Observable";


export interface LesionDialogFormData {
  lesionName: string,
  studyId: number,
  readingId: number,
  readerId: number,
  patientId: number,
  endpointName: string,
  visitConfigId: number,
  isBaseline: boolean,
  markerType:string,
  editDialog:boolean,
  lesionType: OncologyLesionType,
  editType:string,
  lesionData:any,
  screenShotData:any,
  viewerData?:any,
  eCRFOnly:boolean,
  viewerLess: boolean,
  hasComment: boolean,
  lesionList: any[],
  disableLocationEdit: boolean
}


@Component({
  selector: 'app-mrano-efficacy-oncology-lesion-dialog',
  templateUrl: './mrano-efficacy-oncology-lesion-dialog.component.html',
  styleUrls: ['./mrano-efficacy-oncology-lesion-dialog.component.css'],
  providers: [MessagingService]
})
export class MranoEfficacyOncologyLesionDialogComponent implements OnInit, OnDestroy {
  @ViewChild('primaryLocation') primaryLocation: MatSelect;
  lesionType = OncologyLesionType;

  public get editType(): typeof OncologyLesionEditType {
    return OncologyLesionEditType;
  }

  minimized: boolean = false;
  showModalSpinnerLoadingData = false;
  imageUrl: string = '';
  linkData: any;
  imageAttached: boolean = false;
  attachMessage: string;
  primaryLocations = [];
  subLocations = [];
  locationSubscription: Subscription;
  eventSubscription: Subscription;
  lesionForm: FormGroup = new FormGroup({});
  loadingScreenshot = false
  timer$: Observable<string>;
  statusOptions = MranoEfficacyStatusOptions;

  constructor(public dialogRef: MatDialogRef<MranoEfficacyOncologyLesionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: LesionDialogFormData,
              private oncologyAssessmentService: OncologyAssessmentService,
              private primaryBodyLocation: PrimaryBodyLocationService,
              private fb: FormBuilder,
              private messagingService: MessagingService
  ) { }

  attachImage() {
    let message: FormEvent = {key: EventKey.SCREENSHOT_REQUEST, value: ' '};
    this.messagingService.sendRequest(message, FormPage.MRANO);
    this.enableCountDown();
  }

  ngOnInit(): void {
    this.initlesionForm();
    this.getLocations();

    const userId = +JSON.parse(localStorage.getItem('userId'));
    this.eventSubscription = this.messagingService.init(userId, this.data.readingId, FormPage.ONCOLOGY_LESION_DIALOG)
      .subscribe(this.handleEvents.bind(this))
    
    if (!this.data.editDialog || (this.data.editType === this.editType.MEASUREMENT || this.data.editType === this.editType.SCREENSHOT)) 
        this.attachImage();
  }

  handleEvents(event: FormEvent) {
    if (!event.value)
      return;
    switch (event.key) {
      case EventKey.SCREENSHOT_DATA:
        if (event.value) {
          this.setScreenShotToImgFromWebsocket(event.value);
        }
        break;
      default:
        break;
    }
  }
  minimizeDialog(){
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.add('minimized')
    let top = ((dialog[0] as HTMLElement).offsetTop + 60) + 'px';
    document.documentElement.style.setProperty('--dialog-display', "translate3d(0 , calc(100vh - " + top + ") , 0)");
    this.minimized = true;
  }
  maximizeDialog(){
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.remove('minimized')
    document.documentElement.style.setProperty('--dialog-display', "");
    this.minimized = false;
  }

  //get locations
  getLocations(){
    this.showModalSpinnerLoadingData=true;
    this.locationSubscription = this.oncologyAssessmentService.location.subscribe(
      (response)=>{
        if(response){
          this.primaryLocations = response;
          this.primaryLocations = (response as any[]).filter(d => { return d.name.toLowerCase() === 'brain' })
          if(this.data.editDialog)
            this.onEditForm();
          this.showModalSpinnerLoadingData=false;
        }
      }
    )
  }
  initlesionForm(){
    this.lesionForm = this.fb.group(new GlobalLesionModel(
      this.data.lesionType,
      this.data.patientId,
      this.data.readerId,
      this.data.readingId,
      this.data.visitConfigId,
      this.data.endpointName,
      this.data.isBaseline ? true : false,
      this.data.markerType
    ));
    if(this.data.viewerData)
      this.setViewerDataToForm(this.data.viewerData);
  }

  onEditForm(){
      this.lesionForm.setValue(this.data.lesionData);
      this.subLocations = this.primaryLocations.filter(item=>{return item.name == this.data.lesionData.primaryLocation }).map(item =>{return item.subLocations})[0];
      this.lesionForm.patchValue({
        subLocation: this.data.lesionData.subLocation === 'location' && (this.data.editType == this.editType.MEASUREMENT || this.data.editType == this.editType.LOCATION) ? null :  this.data.lesionData.subLocation
      })
    //this.lesionForm.get('subLocation')
      if(this.data.lesionData.snapshot){
        this.imageUrl = this.data.lesionData.snapshot.fileUrl;
      }
      if(this.data.viewerData)
        this.setViewerDataToForm(this.data.viewerData);
  }

  setViewerDataToForm(viewerData) {
    const axialLongDiameter = viewerData.axialLongDiameter == "" ? 'NA' : (+viewerData.axialLongDiameter).toFixed(1);
    const shortAxis = viewerData.shortAxis == "" ? 'NA' : (+viewerData.shortAxis).toFixed(1);
    const ppd = !viewerData.axialLongDiameter ? 'NA' : +((+axialLongDiameter) * (+shortAxis) / 100).toFixed(2);
    this.lesionForm.patchValue({
      type: this.data.lesionType,
      frameNumber: viewerData.frameNumber,
      objectLocationX: viewerData.objectLocationX,
      objectLocationY: viewerData.objectLocationY,
      sopInstanceUid: viewerData.sopInstanceUid,
      sliceNumber: viewerData.sliceNumber,
      seriesId: viewerData.seriesId,
      axialLongDiameter: axialLongDiameter,
      shortAxis: shortAxis,
      ppd: ppd
    });
    if (this.data.lesionType == OncologyLesionType.TARGET_LESION) {
      this.lesionForm.patchValue({
        notMeasurabilityReason: 'PRESENT',
        isMeasurable: true,
      })
    }
  }

  onLocationChange(event: MatSelectChange) {
    this.subLocations = this.primaryLocations.filter(item => {return item.name === event.value }).map(item => {return item.subLocations})[0];
  }

  showStatusOptions() {
    return this.statusOptions[this.data.lesionType];
  }

  getOtherOptionsForMeasurabilityStatus() {
    if (this.data.lesionList) {
      return this.data.lesionList.filter(l => {
        return l.lesionName !== this.data.lesionName
      }).map(l => {return l.lesionName})
    }
  }

  onChangeMeasurabilityStatus(event) {
    this.lesionForm.get('isMeasurable').setValue(false)
    switch (event.value) {
      case 'RESOLVED':
      case 'TOO_SMALL_TO_MEASURE':
      this.lesionForm.patchValue({
        axialLongDiameter : 0,
        shortAxis : 0,
        ppd: 0
      })
        break;
      case 'MERGED_WITH_ANOTHER_LESION':
      this.lesionForm.patchValue({
        mergedWith : event.source.triggerValue.split(' ')[2],
        isMerged : true,
        axialLongDiameter : 0,
        shortAxis: 0,
        ppd: 0,
        ppdChgNadir : 'NA',
      })
        break;
      case 'NOT_EVALUABLE':
      this.lesionForm.patchValue({
        axialLongDiameter : 'NE',
        shortAxis : 'NE',
        ppd : 'NE',
      })
        break;
      case 'PRESENT':
      this.lesionForm.get('isMeasurable').setValue(true)
        break;
      default:
        break;
    }
  }

  private enableCountDown() {
    this.loadingScreenshot = true;
    const stepsInOneSec = 10;
    const time = 10 * stepsInOneSec // 10 seconds
    let period = 1000 / stepsInOneSec;
    this.timer$ = interval(period) // 1000 = 1 second
      .pipe(
        take(time),
        takeWhile(_ => this.loadingScreenshot),
        map((v) => ((time - 1) - v) / stepsInOneSec), // to reach zero
        map(v => v.toFixed(2)),
        finalize(() => this.loadingScreenshot = false)
      )
    // .subscribe((v) => console.log('Countdown: ', v))

  }

  setScreenShotToImgFromWebsocket(snapshot: any) {
    this.data.screenShotData = {
      seriesId: snapshot.cellInfo.seriesId,
      image: snapshot.image,
      preview: snapshot.image,
      sopInstanceUid: snapshot.cellInfo.seriesUID,
      sliceNumber: snapshot.sliceNum + 1,
      modalityName: snapshot.cellInfo.modality,
      sequenceLabelName: snapshot.cellInfo.label
    }
    this.imageUrl = this.data.screenShotData.image;
    this.imageAttached = true;
    this.attachMessage = "";
    this.loadingScreenshot = false;
  }

  setScreenShotToImg(snapshot: any) {
    // @ts-ignore ldb code is set in index.html file. Read ldb document on https://github.com/DVLP/localStorageDB.
    ldb.get(SNAPSHOT_RAW_DATA, (image) => {
      this.data.screenShotData = {
        seriesId: snapshot.cellInfo.seriesId,
        image: image,
        preview: image,
        sopInstanceUid: snapshot.cellInfo.seriesUID,
        sliceNumber: snapshot.sliceNum + 1,
        modalityName: snapshot.cellInfo.modality,
        sequenceLabelName: snapshot.cellInfo.label
      }
      this.imageUrl = this.data.screenShotData.image;
      this.imageAttached = true;
      this.attachMessage = "";

      // @ts-ignore ldb code is set in index.html file. Read ldb document on https://github.com/DVLP/localStorageDB.
      ldb.set(SNAPSHOT_RAW_DATA, '', () => {
      });
    });
  }

  saveImageToCloud(screenShotData) {
    this.showModalSpinnerLoadingData = true;
    // convert base64 string to Blob object ( array of 8 bit integers)
    const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
      const byteCharacters = window.atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    };
    const blob = b64toBlob(screenShotData.image.split(',')[1], 'image/jpeg');
    const blobPreview = b64toBlob(screenShotData.preview.split(',')[1], 'image/jpeg');
    const ifSliceNum = screenShotData.sliceNumber;
    this.oncologyAssessmentService
      .getLesionImageUploadLink(this.data.studyId, this.data.readingId,screenShotData.seriesId, ifSliceNum).subscribe(linkResp => {
      this.linkData = linkResp['data'];
      // fake input form with data
      const fdImage = new FormData();
      fdImage.append('file', blob, 'filename.jpg');
      const fdImagePreview = new FormData();
      fdImage.append('file', blobPreview, 'filename_preview.jpg');
      this.oncologyAssessmentService.uplodFileToCloud(this.linkData.fileUploadUrl, blob).subscribe(imgaeUploadRes => {
        this.oncologyAssessmentService.uplodFileToCloud(this.linkData.fileUploadUrlPreview, blobPreview).subscribe(imgaePreviewUploadRes => {
          const snapshot = new Snapshot();
          snapshot.seriesId=this.data.screenShotData.seriesId;
          snapshot.sliceNumber=this.data.screenShotData.sliceNumber;
          snapshot.storageFileName =  this.linkData['storageFileName'];
          snapshot.storageFileNamePreview=this.linkData['storageFileNamePreview'];
          snapshot.bucketName = this.linkData['bucketName'];
          snapshot.storagePath = this.linkData['storagePath'];
          snapshot.fileUrl =this.linkData['fileUrl'];
          snapshot.fileUrlPreview=this.linkData['fileUrlPreview'];
          snapshot.modalityName = this.data.screenShotData.modalityName;
          snapshot.sequenceLabelName = this.data.screenShotData.sequenceLabelName;
          snapshot.sopInstanceUid = this.data.screenShotData.sopInstanceUid;
          this.lesionForm.patchValue({
            snapshot : snapshot
          })
          this.showModalSpinnerLoadingData = false;
          console.log(this.lesionForm.value)
         this.dialogRef.close(this.lesionForm.value)
        });
      });
    });
  }

  considerEnhancingChange() {
    if (this.lesionForm.get('isEnhanced').value) {
      this.lesionForm.patchValue({
        isMeasurable: true
      })
    } else {
      this.lesionForm.patchValue({
        isMeasurable: false,
      })
    }
    this.considerMeasurabilityChange()
  }

  considerMeasurabilityChange() {
    if (this.lesionForm.get('isMeasurable').value) {
      this.lesionForm.patchValue({
        markerType: MarkerType.BI_RULER,
        notMeasurabilityReason: 'PRESENT'
      })
    } else {
      this.lesionForm.patchValue({
        markerType: MarkerType.MARKER,
        axialLongDiameter: 'NA',
        shortAxis: 'NA',
        ppd: 'NA'
      })
    }
  }

  considerStatusChange() {
    if (this.lesionForm.get('status').value === 'ABSENT') {
      this.lesionForm.patchValue({
        axialLongDiameter: 0,
        shortAxis: 0,
        ppd: 0,
      })
    } else if (this.lesionForm.get('status').value === 'NOT_EVALUABLE') {
      this.lesionForm.patchValue({
        axialLongDiameter: 'NE',
        shortAxis: 'NE',
        ppd: 'NE',
      })
    } 
  }


  OnSave() {
    if (this.data.editDialog && this.data.editType == this.editType.STATUS)
      this.considerStatusChange()
    if (this.data.editDialog && this.data.editType == this.editType.ENHANCING)
      this.considerEnhancingChange()
    if (this.data.editDialog && this.data.editType == this.editType.MEASURABILITY)
      this.considerMeasurabilityChange()
      if(this.imageUrl=='')
        this.lesionForm.patchValue({
          snapshot : null
        })
      if(this.imageAttached)
         this.saveImageToCloud(this.data.screenShotData);
      else
      {
        console.log(this.lesionForm.value)
        this.dialogRef.close(this.lesionForm.value)
      }
  }

  closeClick(){
    this.dialogRef.close();
  }

  validForm() {
    let valid = true;
    if (this.data.editDialog && !this.lesionForm.dirty)
      valid = false;

    if (
      this.data.editDialog &&
      this.data.editType == this.editType.SCREENSHOT &&
      this.imageUrl
    )
      valid = true;
    else if(
        this.data.editDialog &&
          this.data.editType == this.editType.MEASUREMENT &&
          this.imageUrl &&
          this.lesionForm.valid
      )
      valid = true;
    else
      valid = false;

    if (this.data.editDialog && this.lesionForm.dirty &&
      (this.data.editType == this.editType.LOCATION || this.data.editType == this.editType.COMMENT))
      valid = true;

    if (!this.data.editDialog && (!this.lesionForm.valid || !this.imageAttached))
      valid = false;

    if (!this.data.editDialog && this.lesionForm.valid && this.imageAttached)
      valid = true;

    if (this.lesionForm.dirty &&
      (this.data.editType == this.editType.ENHANCING || this.data.editType == this.editType.MEASURABILITY))
      valid = true;
    
    if (this.lesionForm.dirty &&
        (this.data.editType == this.editType.STATUS))
      valid = true;
    
    if (this.lesionForm.dirty &&
        (this.data.editType == this.editType.NOTMEASURABILITYREASON))
      valid = true;
    
    return valid;
  }

  ngOnDestroy() {
    if (this.locationSubscription)
      this.locationSubscription.unsubscribe();

    if (this.eventSubscription)
      this.eventSubscription.unsubscribe();
  }

}
